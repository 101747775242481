<template>
    <div class="grade admin-div">
        <div class="inner section">
            <NoticeBox :lists="noticeLists" showTitle title="평점 관리"/>
            <SortListCard :sorts="info" class="margin"/>
            <GreySelectBox class="margin">
                <Select :selectId="'type'" :selectOptions="dateType" :selectVal="search['type']" :selectChange="getSelectSet" class="small" :labelText="'기간 구분'" />
                &nbsp;&nbsp;
                <DateBox class="regular" :inputId="'startDt'" :inputValue="search['startDt']" :selectFunction="getSelectDate" :timePicker="false" />
                &nbsp;&nbsp;
                <DateBox class="regular" :inputId="'endDt'" :inputValue="search['endDt']" :selectFunction="getSelectDate" :timePicker="false" />
            </GreySelectBox>
            <FilterBox :filters="cnt" class="margin" @filter-changed="getListChange"/>
            <BasicTable :columns="columns" :rows="list" class="margin" @row-click="getListClick"/>
        </div>
    </div>

    <WhiteAlert v-if="showAsk" buttonText="작성완료" title="강의 평가" @close-popup="showAsk = false" @confirm-review="getReview" class="blue">
        <p class="alert-content">강사님의 평점과 리뷰를 작성해 주세요.</p>
        <StarRating @updateScore="getScore" />
        <textarea v-model="eval['memo']" placeholder="내용" @input="getEval"></textarea>
    </WhiteAlert>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmResult"/>
</template>

<script>
import SortListCard from "@/components/card/SortListCard.vue";
import Select from "@/components/input/Select.vue";
import NoticeBox from "@/components/common/NoticeBox.vue";
import GreySelectBox from "@/components/common/GreySelectBox.vue";
import BasicTable from "@/components/table/BasicTable.vue"
import DateBox from "@/components/input/DateBox.vue";
import FilterBox from "@/components/common/FilterBox.vue";
import WhiteAlert from "@/components/alert/WhiteAlert.vue";
import StarRating from "@/components/common/StarRating.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from "vuex";
import { getDate, getConvertDate, getAxiosData } from "@/assets/js/function";
import { getGradeMngList } from "@/assets/js/admin/gradeMng";

export default {
    name: "AdmGradeMng",
    components: {StarRating, WhiteAlert, BasicTable, GreySelectBox, NoticeBox, Select, DateBox, FilterBox, SortListCard, Confirm},
    data() {
        return {
            noticeLists: [
                {content: "평점 답변은 기관들과의 소통을 촉진하고, 개선점을 파악하기 위한 중요한 기회입니다. 학생들의 의견을 존중하며 성숙한 태도로 답변 부탁드립니다."},
                {content: "부정적인 평가에 대해서는 강사의 의견을 단호하게 표현하지 않고, 기관들의 관점을 이해하고 개선방안을 제시하는 방향으로 답변 부탁드립니다."},
                {content: "답변은 빠른 시일 내에 작성해주시고, 간결하고 명확하게 의사소통할 수 있도록 노력해주시길 바랍니다. 기관들의 관심과 의문을 해소할 수 있도록 친절하게 설명해주세요."},
                {content: "평가를 받을 때 기관들의 의견을 적극적으로 반영하고, 개선사항을 지속적으로 추구하는 강사의 모습을 보여주시길 바랍니다."},
            ],
            columns    : [
                {key: "regDt", header: "강의완료 일시", class: "w13"},
                {key: "rate", header: "평점", class: "w6"},
                {key: "status", header: "평가", class: "w6"},
                {key: "name", header: "강사", class: "w6"},
                {key: "subject", header: "강의명", class: "w28 blue"},
                {key: "memo", header: "평점 내용", class: "w41"},
            ],
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            lcCd                : '',
            showAsk             : false
        }
    },
    created() {
        this.getCheck()
    },
    computed : {
        ...mapState('data', {
            dateType  : state => state.data.dateType,
        }),
        ...mapState('gradeMng', {
            search  : state => state.gradeMng.search,
            info    : state => state.gradeMng.info,
            cnt     : state => state.gradeMng.cnt,
            allList : state => state.gradeMng.allList,
            list    : state => state.gradeMng.list,
            eval    : state => state.gradeMng.eval
        })
    },
    methods : {
        getConfirmResult() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getCheck(){
            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                st      : ''
            };

            getGradeMngList(params);
        },
        getSelectSet(id, event) {
            this.search[id] = event;

            const value = event['value'];
            const date  = getDate(value);

            this.search['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
            this.search['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');

            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
            };

            getGradeMngList(params);
        },
        getSelectDate(date, id) {
            this.search[id] = date;

            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                st      : ''
            }

            getGradeMngList(params);
        },
        getListChange(params) {
            const list = [];

            if(params['title'] === '전체')
            {
                this.$store.commit('gradeMng/setList', this.allList);
            }
            else
            {
                this.allList.map((item) => {
                    let title = '';

                    if(params['title'] === '평가 완료')
                    {
                        title = '완료';
                    }
                    
                    if(item.status === title)
                    {
                        list.push({
                            regDt   : item.regDt,
                            name    : item.name,
                            rate    : item.rate,
                            status  : item.status,
                            subject : item.subject,
                            memo    : item.memo
                        })
                    }
                });

                this.$store.commit('gradeMng/setList', list);
            }
        },
        getListClick(data) {
            this.$store.commit('gradeMng/setEvalReset');
            this.lcCd = data['lcCd'];
            this.showAsk = true;
        },
        getScore(score) {
            this.eval['score'] = score;
        },
        getEval() {
            this.eval['memo'] = event.target.value;
        },
        async getReview() {
            try
            {
                const params = {
                    lcCd    :   this.lcCd,
                    rate    :   this.eval['score'] === '' ? 0 : this.eval['score'],
                    memo    :   this.eval['memo']
                };

                const res = await getAxiosData('https://data.wecls.com/organ/dashboard/rateProc', params);

                this.showAsk = false;
                this.$store.commit('gradeMng/setEvalReset');

                if(res['status'] === 200)
                {
                    this.getConfirmSet('alert', '완료', '평점이 등록되었습니다.');
                    getGradeMngList({
                        startDt : this.search['startDt'],
                        endDt   : this.search['endDt']
                    });
                }
                else 
                {
                    this.getConfirmSet('alert', '에러', '에러가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }

            }
            catch(error)
            {
                console.log(error);
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout', 'organ');
                    this.$router.push('/organLogin');
                }
                else
                {
                    this.getConfirmSet('alert', '에러', '에러가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.grade {
    padding: 90px 0;

    .margin {
        margin-top: 46px;
    }
}

.alert {
    .alert-content {
        text-align: center;
        font-size: $font12;
        font-weight: 400;
        color: $text-color-lv-1;
    }

    textarea{
        padding: 14px;
        border-radius: 4px;
        border: 1px solid $line-color-lv-2;
        background-color: $bk-color-lv-3;
        width: 300px;
        height: 120px;
        resize: none;
        margin-bottom: 25px;
        font-size: $font14;
        font-weight: 400;
        color: $text-color-lv-1;
    }
}
</style>