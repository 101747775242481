<template>
    <div class="message">
        <MypageHeader />
        <div class="inner section">
            <NoticeBox :lists="noticeLists" showTitle title="메세지" />
            <div class="margin">
                <vue-advanced-chat 
                    :current-user-id="itCd" 
                    :rooms="JSON.stringify(rooms)"
                    :rooms-loaded="true"
                    :load-first-room="false"
                    :messages="JSON.stringify(messages)"
                    :messages-loaded="messagesLoaded"
                    :show-files="true"
                    :accepted-files="'image/gif, image/jpeg, image/jpg, image/png'"
                    @before-upload="getChatFileUpload"
                    :show-audio="false"
                    :show-emojis="false"
                    :show-add-room="false"
                    @send-message="sendMessage" 
                    @fetch-messages="fetchMessages"
                    @open-file="getOpenFile"
                />
                <!-- https://www.npmjs.com/package/vue-advanced-chat -->
            </div>
        </div>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmResult"/>
</template>

<script>
import MypageHeader from "@/views/user/mypage/MypageHeader.vue";
import NoticeBox from "@/components/common/NoticeBox.vue";
import { register } from 'vue-advanced-chat';
import { getConvertDate } from "@/assets/js/function";
import { getRooms, getMessages } from "@/assets/js/user/userMsg";
import Confirm from "@/components/alert/Confirm.vue";
import axios from "axios";

import { mapState } from 'vuex';

register()

export default {
    name: "MessageChat",
    components: { NoticeBox, MypageHeader, Confirm },
    computed: {
        ...mapState('userMsg', {
            rooms       : state => state.userMsg.rooms,
            messages    : state => state.userMsg.messages,
            selRoomId   : state => state.userMsg.roomId
        }),
        ...mapState('login', {
            itCd  : state => state.login.itCd
        })
    },
    data() {
        return {
            noticeLists: [
                { content: "비속어나 공격적인 언어를 사용하지 말아주세요." },
                { content: "개인정보는 공개하지 말고, 개인정보를 요청하지 말아주세요." },
                { content: "상대방에 대해 비방하거나 비난하지 말아주세요." },
                { content: "상대방이 계좌이체 등 직접 결제를 요구하면 거절하시고 즉시 위클즈 고객센터로 알려주세요." }
            ],
            chatClient      : null,
            // currentUserId   : 'IA0028',
            messagesLoaded  : false,
            files           : []
        }
    },
    created() {
        /** 웹 소켓 URL 선언 */
        this.chatClient = new WebSocket('wss://vfqg19h2ja.execute-api.ap-northeast-2.amazonaws.com/production/');

        /** 웹 소켓 연결 => 성공 시  */
        this.chatClient.onopen = async () => {
            await this.getRooms();

            const firstData = {
                action : 'chatConnect',
                regDt  : getConvertDate(new Date(), 'yyyy-mm-dd hh:ii:ss'),
                userCd : this.itCd
            };

            console.log(firstData);

            this.chatClient.send(JSON.stringify(firstData));
        };

        /** 메세지 전송 데이터 받는 함수 */
        this.chatClient.onmessage = (result) => {
            const msg = JSON.parse(result.data);
            console.log(msg);

            let files = null;

            if(msg.message['imageUrls'].length > 0)
            {
                files = msg.message['imageUrls'].map((item, index) => {
                    return {
                        name     : `file_${index}`,
                        type     : 'image/gif, image/jpeg, image/jpg, image/png',
                        saved    : true,
                        url      : item,
                        preview  : item
                    }
                });
            }

            if(msg.message['roomId'] === this.selRoomId)
            {
                this.messages.push({
                    _id         : msg.message['ikey'],
                    content     : msg.message['message'],
                    senderId    : msg.message['senderCd'],
                    timestamp   : msg.message['regDt'],
                    files       : files
                });
            }

            this.files = [];
            this.getRooms();
        };

        /** 웹 소켓 연결 종료 시 함수 */
        this.chatClient.onclose = () => {
            if(window.location.pathname === '/user/mypage/message')
            {
                alert('장시간 입력이 없어 이전 페이지로 이동합니다.');
                this.$router.go(-1);
            }
        };
    },
    methods: {
        getConfirmResult() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        async getRooms() {
            await getRooms();
        },
        fetchMessages(data) {
            const roomId = data.detail[0].room['roomId'];

            this.messagesLoaded = true;

            const params = {
                roomId : roomId
            };

            getMessages(params);

            const room = this.rooms.find(room => room.roomId === roomId);
            room['unreadCount'] = 0;

            this.$store.commit('userMsg/setRoomId', roomId);

            const roomSel = {
                action : 'checkRoom',
                userCd : this.itCd,
                roomId : roomId
            };

            this.chatClient.send(JSON.stringify(roomSel));
        },
        getOpenFile(event) {
            const url = event.detail[0].file['file']['url'];
            const downloadLink      = document.createElement('a');

            downloadLink.href       = url;
            downloadLink.download   = 'image';
            downloadLink.click();
        },
        /** 메세지 파일 업로드 시 */
        getChatFileUpload(file) {
            const allowedTypes = ['gif', 'jpeg', 'jpg', 'png'];

            if (!allowedTypes.includes(file.type)) 
            {
                this.getConfirmSet('alert', '이미지 파일만 가능', '이미지 파일 중 gif, jpeg, jpg, png 형태만 가능합니다.')
                return false;
            }

            return true;
        },
        /** 메세지 가공하여 웹 소켓으로 전송 */
        async sendMessage(message) {
            await this.getFiles(message.detail[0].files);

            if(this.chatClient.readyState === WebSocket.OPEN)
            {
                const room  = this.rooms.find(room => room.roomId === message.detail[0].roomId);

                const data = {
                    action          : 'sendmessage',
                    regDt           : getConvertDate(new Date(), 'yyyy-mm-dd hh:ii:ss'),
                    message         : message.detail[0].content, 
                    roomId          : message.detail[0].roomId,
                    senderCd        : this.itCd,
                    lastDt          : '0',
                    receiverCd      : room['lastMessage']['senderId'],
                    files           : this.files
                };

                this.chatClient.send(JSON.stringify(data));
            }
            else
            {
                this.getConfirmSet('alert', '에러', '메세지 통신 연결 중 문제가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
            }
        },
        async getFiles(files) {
            if(files === null)
            {
                return files
            }
            else
            {
                const formData = new FormData();

                files.forEach((item, index) => {
                    formData.append(`files[${index}]`, item.blob, item.name);
                });

                try
                {
                    const instance = axios.create({
                        withCredentials : true,
                        headers : {
                            'X-Subdomain'  : window.location.hostname
                        }
                    });
                    
                    const res   = await instance.post('https://data.wecls.com/chatMessage/uploadToPhp', formData);
                    const files = res.data['data'];

                    this.files = files;
                }
                catch(error)
                {
                    console.log(error);
                    if(error.response.data.status === 500)
                    {
                        alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                        this.$store.dispatch('login/logout', 'inst');
                        this.$router.push('/login');
                    }
                    else if(error.response.data.status === 400)
                    {
                        this.getConfirmSet('alert', '에러', error.response.data.data);
                    }
                    else
                    {
                        this.getConfirmSet('alert', '에러', '이미지 업로드에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.');
                    }
                }
            }
        }
    },
    beforeUnmount() {
        this.chatClient.close();
    }
}
</script>

<style lang="scss" scoped>
.message {
    padding: 90px 0;

    .margin {
        margin-top: 46px;
    }
}
</style>