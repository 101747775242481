import { getDate, getConvertDate } from "@/assets/js/function";

const date = getDate('mon');
/**
 * @description 평점관리 vuex 모듈 관련
 * @author 김원명, @date 2024-01-10
 */
const lectModule = {
    namespaced  : true,
    state       : {
        lect : {
            search  : {
                type    : { name : '이달', value : 'mon' },
                startDt : getConvertDate(date['st'], 'yyyy-mm-dd'),
                endDt   : getConvertDate(date['et'], 'yyyy-mm-dd')
            },
            filters    : [
                { title : '진행 강의', count : 0 },
                { title : '완료 강의', count : 0 },
                { title : '전체', count : 0 }
            ],
            allList : [],
            list    : []
        }
    },
    mutations : {
        setFilters(state, newFilters)
        {
            state.lect.filters = newFilters;
        },
        setAllList(state, newAllList) 
        {
            state.lect.allList = newAllList;
            state.lect.list    = newAllList;
        },
        setList(state, newList)
        {
            state.lect.list = newList;
        }
    }
};
  
export default lectModule;