<template>
    <div class="calc">
        <MypageHeader />
        <p class="border" />
        <div class="inner section">
            <SortListCard :sorts="amt" class="margin" />
            <GreySelectBox class="margin">
                <Select :selectId="'type'" :selectOptions="dateType" :selectVal="search['type']" :selectChange="getSelectSet" class="small" :labelText="'기간 구분'" />
                &nbsp;&nbsp;
                <DateBox class="regular" :inputId="'startDt'" :inputValue="search['startDt']" :selectFunction="getSelectDate" :timePicker="false" />
                &nbsp;&nbsp;
                <DateBox class="regular" :inputId="'endDt'" :inputValue="search['endDt']" :selectFunction="getSelectDate" :timePicker="false" />
            </GreySelectBox>
            <FilterBox :filters="cnt" class="margin" @filter-changed="getListChange"/>
            <BasicTable :columns="columns" :rows="list" class="margin" @row-click="getDetail"/>
        </div>
    </div>
</template>

<script>
import SortListCard from "@/components/card/SortListCard.vue";
import Select from "@/components/input/Select.vue";
import GreySelectBox from "@/components/common/GreySelectBox.vue";
import BasicTable from "@/components/table/BasicTable.vue";
import MypageHeader from "@/views/user/mypage/MypageHeader.vue";
import DateBox from "@/components/input/DateBox.vue";

import FilterBox from "@/components/common/FilterBox.vue";

import { mapState } from "vuex";
import { getDate, getConvertDate } from "@/assets/js/function";
import { getCalcList } from "@/assets/js/user/calc";

export default {
    name: "CalcMng",
    components: { Select, FilterBox, DateBox, MypageHeader, BasicTable, GreySelectBox, SortListCard },
    data() {
        return {
            noticeLists: [
                { content: "강의를 진행해 주셔서 감사합니다. 홍길동 강사님의 최근 5년 이내 입금 내역을 조회 가능하며, 관련 내용에 대한 문의가 있을 경우 언제든지 연락주시기 바랍니다." },
                { content: "강의 비용에 대해서는 강의 금액에 세금계산서 또는 현금영수증을 발행해 드리고 있습니다. 발행에 필요한 정보나 요청사항이 있으시면 미리 알려주시기 바랍니다." },
                { content: "강의가 완료된 후 2주일 이내에 정산이 이루어지며 관련 내용에 대한 문의가 있을 경우 언제든지 연락주시기 바랍니다." },
                { content: "강의료 정산 시, 정확한 은행 정보를 기재해주셔야 원활한 입금이 가능합니다. 강의료 정산 관련하여 어떠한 문제가 있으시면 곧바로 연락 부탁드립니다." },
            ],
            columns: [
                { key: "status", header: "상태", class: "w15" },
                { key: "lectPeriod", header: "강의일", class: "w20" },
                { key: "subject", header: "강의명", class: "w30 blue" },
                { key: "amt", header: "정산 금액", class: "w15" },
                { key: "calcPrice", header: "실제 정산금", class: "w10" },
                { key: "regDt", header: "정산 요청일", class: "w10" },
                { key: "calcGb", header: "데이페이", class: "w8" },
                { key: "calcMean", header: "정산 수단", class: "w8" },
                { key: "docEunm", header: "계산서", class: "w8" },
                { key: "modDt", header: "정산 일시", class: "w10" }
            ]
        }
    },
    created() {
        this.getStack();
    },
    computed: {
        ...mapState('data', {
            dateType  : state => state.data.dateType,
        }),
        ...mapState('calc', {
            search  : state => state.calc.search,
            amt     : state => state.calc.amt,
            cnt     : state => state.calc.cnt,
            allList : state => state.calc.allList,
            list    : state => state.calc.list
        })
    },
    methods: {
        getStack() {
            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                st      : ''
            }

            getCalcList(params);
        },
        getSelectSet(id, event) {
            this.search[id] = event;

            const value = event['value'];
            const date  = getDate(value);

            this.search['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
            this.search['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');

            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
            };

            getCalcList(params);
        },
        getSelectDate(date, id) {
            this.search[id] = date;

            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                st      : ''
            }

            getCalcList(params);
        },
        getListChange(params) {
            if(params['title'] === '전체')
            {
                this.$store.commit('calc/setList', this.allList);
            }
            else
            {
                const list = this.allList.filter((item) => {
                    return item.status === params['title'];
                });

                this.$store.commit('calc/setList', list);
            }
        },
        getDetail(params){
            this.$router.push({ path: `/user/mypage/calcSub/${params['mtCd']}` });
        }
    }
}



</script>

<style lang="scss" scoped>
.calc {
    padding: 90px 0;

    .margin {
        margin-top: 46px;
    }
}
</style>