<template>
    <div>
        <MainHeader/>

        <div class="main-banner main-box">
            <h1 data-aos="fade-up">
                ✨ 위클즈와 함께라면<br>
                <span class="brand">강사, 기관, 개인을</span> 손쉽게 연결합니다.
            </h1>

            <p data-aos="fade-up">
                전문가 강사 서칭부터 계약 체결까지 바쁜 운영관리<br>
                복잡한 서류부터 사무업까지 언제 챙기지?
            </p>

            <div data-aos="fade-up">
                <a href="" class="black">
                    <img src="@/assets/images/icon_chat.png" alt="무료 상담받기" title="무료 상담받기"/>
                    <span>무료 상담받기</span>
                </a>

                <a href="" download class="white">
                    <img src="@/assets/images/icon_download.png" alt="제안서 받기" title="제안서 받기"/>
                    <span>제안서 받기</span>
                </a>
            </div>

            <MainSlide/>
        </div>

        <div class="why-us contact main-box">
            <div class="inner">
                <h5 class="brand" data-aos="fade-up">
                    WHY US?
                </h5>

                <h1 data-aos="fade-up">
                    💣강사님 아직도 발로 뛰며 영업하시나요?
                </h1>

                <p data-aos="fade-up">
                    유능한 영업사원, 위클즈와 함께 해요.<br>
                    많은 강사님들이 눈에 띄는 변화를 경험하고 있답니다.
                </p>

                <ImageCard/>
            </div>
        </div>

        <div class="why-us review-box main-box">
            <div class="inner">
                <h5 class="brand" data-aos="fade-up">
                    WHY US?
                </h5>

                <h1 data-aos="fade-up">
                    🎯 기관 담당자님을 위한 최적의 강사 선정 도우미
                </h1>

                <p data-aos="fade-up">
                    기관 담당자님들을 위한 유능한 비서가 되어드립니다.<br>
                    귀찮은 일은 위클즈가 처리해 드릴게요 :)
                </p>

                <Image2Card/>
            </div>
        </div>

        <!--        <div class="why-us main-box">
                    <div class="inner">
                        <h5 class="brand" data-aos="fade-up">
                            WHY US?
                        </h5>

                        <h1 data-aos="fade-up">
                            🔒 개인 회원님들을 위한 인증된 안전한 교육
                        </h1>

                        <p data-aos="fade-up">
                            위클즈의 깐깐한 강사 검증을 통한<br>
                            안전하고 수준 높은 강의를 여러 장소에서도 즐겨보세요.
                        </p>

                        <Image3Card/>
                    </div>
                </div>-->

        <div class="review-box why-us main-box">
            <div class="inner">
                <h5 class="brand" data-aos="fade-up">
                    WHY US?
                </h5>

                <h1 data-aos="fade-up">
                    🌟 고객님들의 진짜 리뷰
                </h1>

                <p data-aos="fade-up">
                    위클즈의 깐깐한 강사 검증을 통한<br>
                    안전하고 수준 높은 강의를 여러 장소에서도 즐겨보세요.
                </p>

                <ReviewCard data-aos="fade-up"/>
            </div>
        </div>

        <div class="why-us contact main-box no-border">
            <div class="inner">
                <h5 class="brand" data-aos="fade-up">
                    CONTACT
                </h5>

                <h1 data-aos="fade-up">
                    📬 위클즈 문의하기
                </h1>

                <p data-aos="fade-up">
                    궁금한 사항이 있으시다면 언제든지 문의해주세요. 저희는 항상 여러분의 의견을 듣고자 합니다.
                </p>

                <ContactBox data-aos="fade-up"/>
            </div>
        </div>
        <MainFooter/>
    </div>
</template>

<script>
import MainSlide from "@/views/static/MainSlide.vue";
import ImageCard from "@/views/static/ImageCard.vue";
import MainHeader from "@/views/static/MainHeader.vue";
import Image2Card from "@/views/static/Image2Card.vue";
import ReviewCard from "@/views/static/ReviewCard.vue";
// import Image3Card from "@/views/static/Image3Card.vue";
import ContactBox from "@/views/static/ContactBox.vue";
import MainFooter from "@/views/static/MainFooter.vue";

export default {
    name      : "indexVue",
    components: {MainFooter, ContactBox, ReviewCard, Image2Card, MainHeader, ImageCard, MainSlide},
};
</script>

<style lang="scss" scoped>
.main-box {
    width: 100%;
    color: $text-color-lv-2;
    text-align: center;
    border-bottom: 20px solid $bk-color-lv-2;

    &.no-border {
        border-bottom: 0;
    }

    h1 {
        font-size: $font40;
    }

    p {
        font-size: $font24;
        margin: 42px 0;
    }
}

.main-banner {
    padding: 200px 0;

    div {
        width: 100%;
        margin: 0 auto;
        text-align: center;

        a {
            padding: 16px 24px;
            border-radius: 8px;
            border: 1px solid $text-color-lv-2;
            font-weight: 700;
            display: inline-block;

            &:first-child {
                margin-right: 9px;
            }

            img {
                vertical-align: middle;
                margin-right: 8px;
            }

            span {
                vertical-align: middle;
            }

            &.black {
                background-color: $text-color-lv-2;
                color: $bk-color-lv-3;
            }

            &.white {
                background-color: $bk-color-lv-3;
            }
        }
    }
}

.why-us {
    padding: 120px 0;

    .inner {
        & > p {
            margin-bottom: 80px;
            font-size: $font20;
        }
    }
}

.review-box {
    background-color: $bk-color-lv-4
}

.contact {
    background-color: $bk-color-lv-1;
}
</style>