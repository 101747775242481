<template>
    <MainSideHeader />
    <MainHeader class="admin-header" />
    <slot />
</template>

<script>
import MainHeader from "@/views/admin/MainHeader.vue";
import MainSideHeader from "@/views/admin/MainSideHeader.vue";

export default {
    name: "AdminHeader",
    components: {MainSideHeader, MainHeader}
}
</script>

<style scoped>
.admin-header{
    background-color: #FBFBFD;
}
</style>