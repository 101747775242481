<template>
    <div class="profile admin-div">
        <div class="inner section">

            <AdmProfileTable @get-check="getConfirmSet('alert', '안내', '기관 정보 입력 후 승인요청을 해주셔야 합니다.<br>관리자 승인 후 위클즈 서비스 이용이 가능합니다.')"/>

            <div class="button-wrapper">
                <BaseButton class="grey profile-button" @click="getSaveProfile('Y')" buttonText="임시저장" />
                <BaseButton class="profile-button" @click="getApproval" buttonText="승인요청 하기" />
            </div>
        </div>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import AdmProfileTable from "@/views/admin/template/AdmProfileTable.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import Confirm from "@/components/alert/Confirm.vue";
import axios from "axios";

import { mapState } from 'vuex';

export default {
    name: "AdmProfileMng",
    components: { BaseButton, AdmProfileTable, Confirm },
    data() {
        return {
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            resultYn            : 'N',
            approval            : 'N'
        }
    },
    computed: {
        ...mapState('profileAdm', {
            info    : state => state.profileAdm.info
        })
    },
    methods: {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.approval === 'Y')
            {
                this.getSaveProfile('N');
            }

            if(this.resultYn === 'Y')
            {
                // console.log('success');
                location.reload();
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
            this.approval    = 'N';
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getValCheck() {
            if(this.info['ogNm'] === '')
            {
                this.getConfirmSet('alert', '에러', '기관명을 입력하세요.');
                return false;
            }

            if(this.info['addr'] === '')
            {
                this.getConfirmSet('alert', '에러', '주소를 검색하여 입력하세요.');
                return false;
            }

            return true;
        },
        getApproval() {
            if(!this.getValCheck())
            {
                return false;
            }

            this.getConfirmSet('confirm', '승인 요청', '승인 요청하시겠습니까?');

            this.approval = 'Y';
        },
        async getSaveProfile(tmpYn) {
            if(!this.getValCheck())
            {
                return false;
            }
            
            const formData = new FormData();

            formData.append('ogCd',         this.info['ogCd']);
            formData.append('oaCd',         this.info['oaCd']);
            formData.append('cntry',        this.info['cntry']);
            formData.append('ogNm',         this.info['ogNm']);
            formData.append('zip',          this.info['zip']);
            formData.append('addr',         this.info['addr']);
            formData.append('addrDetail',   this.info['addrDetail']);
            formData.append('charPerNm',    this.info['charPerNm']);
            formData.append('charPerTel',   this.info['charPerTel']);
            formData.append('tel',          this.info['tel']);
            formData.append('fax',          this.info['fax']);
            formData.append('memo',         this.info['memo']);
            formData.append('bankCd',       this.info['bankCd']);
            formData.append('bankNm',       this.info['bankNm']);
            formData.append('bankNum',      this.info['bankNum']);
            formData.append('einNum',       this.info['einNum']);
            formData.append('ceoNm',        this.info['ceoNm']);
            formData.append('einNm',        this.info['einNm']);
            formData.append('einType',      this.info['einType']);
            formData.append('einItem',      this.info['einItem']);
            formData.append('einEmail',     this.info['einEmail']);
            formData.append('tmpYn',        tmpYn);

            if(this.info['img1']['file'] !== null)
            {
                formData.append('img1', this.info['img1']['file']);
            }

            if(this.info['img2']['file'] !== null)
            {
                formData.append('img2', this.info['img2']['file']);
            }

            if(this.info['img3']['file'] !== null)
            {
                formData.append('img3', this.info['img3']['file']);
            }

            if(this.info['img4']['file'] !== null)
            {
                formData.append('img4', this.info['img4']['file']);
            }
            
            if(this.info['img5']['file'] !== null)
            {
                formData.append('img5', this.info['img5']['file']);
            }

            if(this.info['img6']['file'] !== null)
            {
                formData.append('img6', this.info['img6']['file']);
            }

            const fileOther = [];

            this.info['fileOther'].forEach((item) => {
                if(item.file !== null)
                {
                    fileOther.push(item.file);
                }
            });

            if(fileOther.length !== 0)
            {
                fileOther.forEach((data, index) => {
                    formData.append(`fileOther[${index}]`, data);
                });
            }

            formData.append('delYn',    this.info['delYn']);

            if(this.info['fileGb'].length !== 0)
            {
                this.info['fileGb'].forEach((item, index) => {
                    formData.append(`fileGb[${index}]`, JSON.stringify(item));
                });
            }

            const formDataJson = {};

            formData.forEach((value, key) => {
                formDataJson[key] = value;
            });

            console.log(formDataJson);

            try 
            {
                const instance = axios.create({
                    withCredentials : true,
                    headers : {
                        'X-Subdomain'  : window.location.hostname
                    }
                });
                
                const res = await instance.post('https://data.wecls.com/organ/registInfo/newData', formData);

                console.log(res);

                if(res.status === 200)
                {
                    this.resultYn = 'Y';
                    this.approval = 'N';
                    this.getConfirmSet('alert', '완료', (tmpYn === 'Y' ? '임시 저장되었습니다.' : '승인 요청되었습니다.'));
                }
                else
                {
                    this.getConfirmSet('alert', '에러', res.data.data);
                }
            } 
            catch (error) 
            {
                console.log(error);
                this.approval = 'N';

                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout', 'organ');
                    this.$router.push('/organLogin');
                }
                else 
                {
                    this.getConfirmSet('alert', '에러', error.response.data.data);
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.profile {
    padding: 90px 0;

    .margin {
        margin-top: 46px;
    }

    .notice-title {
        font-size: $font28;
        font-weight: 700;
        margin-bottom: 46px;

        span {
            font-weight: 500;
            display: block;
        }
    }

    .button-wrapper {
        text-align: center;
        margin-top: 46px;

        .profile-button {
            padding: 10px;
            border-radius: 4px;
            width: 146px;
            display: inline-block;

            &.grey {
                margin-right: 16px;
            }
        }
    }
}
</style>