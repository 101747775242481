<template>
    <div class="calender">
        <MypageHeader />
        <p class="border" />
        <div class="inner section">
            <div class="calender-wrapper margin">
                <FullCalendar :options="calendarOptions" />
                <!-- https://fullcalendar.io/docs/vue -->
            </div>
        </div>
    </div>
</template>

<script>
import MypageHeader from "@/views/user/mypage/MypageHeader.vue";
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import { getDateInfo } from "@/assets/js/user/date";

export default {
    name: "CalenderList",
    components: { MypageHeader, FullCalendar },
    created() {
        this.getStatCheck()
    },
    computed: {
        calendarOptions() {
            return {
                plugins     : [dayGridPlugin],
                initialView : 'dayGridMonth',
                events      : this.$store.state.date.dateInfo.info,
            }
        },
    },
    methods : {
        getStatCheck() {
            getDateInfo();
        }
    }
}
</script>

<style lang="scss" scoped>
.calender {
    padding: 90px 0;

    .margin {
        margin-top: 46px;
    }
}
</style>