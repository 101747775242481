import { getAxiosData } from "@/assets/js/function";
import store from "@/store";
import { useRouter } from "vue-router";

/**
 *  @description 공고 지원자 리스트
 */
export async function getUserMngDetailList(params) 
{
    const router = useRouter();

    try 
    {
        const res = await getAxiosData('https://data.wecls.com/organ/dashboard/lectVolList', params);

        let recp  = 0;
        let pass1 = 0;
        let pass2 = 0;
        let final = 0;
        
        const list = res.data.map(item => {
            let stNm = '';

            switch(item.state)
            {
                case 'E':
                    stNm = '지원자 접수';
                    recp++;
                break;
                case 'D':
                    stNm = '서류 통과';
                    pass1++;
                break;
                case 'I':
                    stNm = '2차 검증';
                    pass2++;
                break;
                case 'Y':
                    stNm = '최종 매칭';
                    final++;
                break;
            }

            return {
                stNm        : stNm,
                itCd        : item.itCd,
                iaCd        : item.iaCd,
                name        : item.name,
                career      : item.career,
                likeSt      : item.likeSt,
                appeal      : item.appeal,
                fieldNm     : item.fieldNm,
                repCareer   : item.repCareer.join(', '),
                school      : item.school,
                major       : item.major,
                fileCnt     : item.fileCnt,
                matchCnt    : item.matchCnt,
                rate        : item.rate
            }
        });

        const cnt = [
            { title : '지원자 접수', count : recp },
            { title : '서류 통과', count : pass1 },
            { title : '2차 검증', count : pass2 },
            { title : '최종 매칭', count : final },
            { title : '전체', count : list.length }
        ];

        store.commit('userMngDetail/setCnt', cnt);
        store.commit('userMngDetail/setAllList', list);
    } 
    catch (error) 
    {
        console.error(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'organ');
            router.push('/organLogin');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}