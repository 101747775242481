import { getDate, getConvertDate } from "@/assets/js/function";

const date = getDate('day');

/**
 * @description 강의 모집 등록  vuex 모듈 관련
 * @author 정해원, @date 2023-11-24 / (수정) @author 김원명, @date 2023-12-20
 */
const collectMngModule = {
    namespaced: true,
    state: {
        collect: {
            info    : getCollectInfo()
        }
    },
    mutations: {
        setInfo(state, newInfo)
        {
            state.collect.info = newInfo
        },
        setArea1(state, newArea1)
        {
            state.collect.info.area1 = newArea1
        },
        setArea2(state, newArea2)
        {
            state.collect.info.area2 = newArea2
        },
        setSelect(state, {gb, value})
        {
            state.collect.info[gb] = value;
        },
        updateFile(state, { fileType, file }) 
        {
            state.collect.info[fileType] = {
                text: file.name,
                file: file
            };
        },
        clearFile(state, key) 
        {
            if (state.collect.info[key]['file'] !== null) 
            {
                state.collect.info[key] = {
                    text : '',
                    file : null
                };
            }
        },
        getDataFile(state, { gb, index }) 
        {
            if(gb === 'A')
            {
                state.collect.info.fileOther.push({ text : '', file : null, gb : 'N' });

                const fileCheck = state.collect.info['fileGb'].indexOf(item => item.fileGb === 'F' && item.fileNo === (index + 1));

                if(fileCheck !== -1)
                {
                    state.collect.info['fileGb'].splice(fileCheck, 1);
                }
    
                if(state.collect.info['fileGb'].length === 0)
                {
                    state.collect.info['delYn'] = 'N';
                }
            }
            else
            {
                const check = state.collect.info['fileGb'].some(item => item.fileGb === 'F' && item.fileNo === (index + 1));

                if(state.collect.info['fileOther'][index]['gb'] === 'Y')
                {
                    state.collect.info['delYn'] = 'Y';
                }

                if (!check) 
                {
                    state.collect.info['fileGb'].push({fileGb : 'F', fileNo : (index + 1)});
                }

                if(index !== 0)
                {
                    state.collect.info.fileOther.splice(index, 1);
                }
                else
                {
                    state.collect.info.fileOther[0] = {
                        text : '',
                        file : null,
                        gb   : 'N'
                    };
                }
            }
        },
        getDataFileSelect(state, { index, file }) 
        {
            state.collect.info.fileOther[index] = {
                text: file.name,
                file: file
            };
        },
        setBase(state, {gb, value})
        {
            state.collect.info[gb] = value;
        },
        setReset(state)
        {
            state.collect.info = getCollectInfo()
        }
    }
};

function getCollectInfo() { 
    return {
        img1            : getFileObject(1),
        img2            : getFileObject(2),
        img3            : getFileObject(3),
        img4            : getFileObject(4),
        subject         : '',
        fieldCd         : '',
        lectGb          : 'O',
        startDt         : getConvertDate(date['st'], 'yyyy-mm-dd'),
        endDt           : getConvertDate(date['et'], 'yyyy-mm-dd'),
        startTime       : '',
        endTime         : '',
        amt             : '',
        lectRealDt      : '',
        lectRealTime    : '',
        totalTime       : '',
        estAmt          : '',
        target          : '',
        area1           : '',
        area2           : '',
        zip             : '',
        addr            : '',
        addrDetail      : '',
        lectEq          : '',
        keyword         : '',
        career          : '',
        acadAbil        : '',
        startRegDt      : getConvertDate(date['st'], 'yyyy-mm-dd'),
        endRegDt        : getConvertDate(date['et'], 'yyyy-mm-dd'),
        reqDoc          : '',
        etcDoc          : '',
        lectMemo        : '',
        ogNm            : '',
        location        : '',
        chEmail         : '',
        chPerTel        : '',
        chPerNm         : '',
        fileOther       : [{
            text    : '',
            file    : null
        }],
        fileGb          : [],
        delYn           : 'N'
    };
}

function getFileObject(num)
{
    return {
        num  : num,
        text : '',
        file : null,
        gb   : 'N'
    }
}

export default collectMngModule;
