<template>
    <div class="join intro">
        <MobileBox title="약관동의" showPrev>
            <div class="join-container">
                <div>
                    <h2>
                        <span>위클즈</span>
                        약관에 동의해주세요.
                    </h2>

                    <p>모든 이용자는 위클즈의 이용약관 및 개인정보 처리 방침에 동의해야합니다.</p>
                </div>

                <CheckBox labelText="모두 동의합니다." inputId="all" inputNm="agree" :checkStat="getCheckStat('all')" @change="getAllCheck"/>

                <span class="border" />

                <CheckBox labelText="[필수] 만 14세 이상입니다." inputId="age" inputNm="agree" :checkStat="getCheckStat('age')" @change="getCheckbox"/>
                <CheckBox labelText="[필수] 이용약관 동의" inputId="use" inputNm="agree" :checkStat="getCheckStat('use')" showDetail @change="getCheckbox" @get-detail="getTerms"/>
                <CheckBox labelText="[필수] 개인정보 수집 - 이용 동의" inputId="info" :checkStat="getCheckStat('info')" inputNm="agree" showDetail @change="getCheckbox" @get-detail="getTerms"/>
                <CheckBox labelText="[선택사항] 마케팅 정보 수신 동의" inputId="choice" :checkStat="getCheckStat('choice')" inputNm="agree" showDetail @change="getCheckbox" @get-detail="getTerms"/>
                <div class="bottom">
                    <BaseButton buttonText="동의하고 계속하기" :class="btnClass" @click="getAgreeNext" />
                </div>
            </div>
        </MobileBox>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmResult"/>

    <RoundPopup v-if="popStat" @get-close="popStat = false" :contents="contents">
    </RoundPopup>
</template>

<script>
import MobileBox from "@/components/common/MobileBox.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import CheckBox from "@/components/input/CheckBox.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from 'vuex';
import { getCertified } from "@/assets/js/certified";
import axios from 'axios';
import RoundPopup from "@/components/popup/RoundPopup.vue";

export default {
    name: "JoinAgree",
    components: {RoundPopup, CheckBox, BaseButton, MobileBox, Confirm},
    computed: {
        ...mapState('join', {
            certified   : state => state.joinInfo.certified,
            agree       : state => state.joinInfo.agree,
            terms       : state => state.joinInfo.terms,
        })
    },
    created() {
        this.getTermsInfo();
    },
    data () {
        return {
            btnClass            : 'grey',
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            popStat             : false,
            contents            : ''
        }
    },
    methods: {
        async getTermsInfo() {
            try
            {
                const res = await axios.post('https://data.wecls.com/terms/getInfo');

                console.log(res);

                const terms = {
                    age    : res.data['data']['terms1'],
                    use    : res.data['data']['terms2'],
                    info   : res.data['data']['terms3'],
                    choice : res.data['data']['terms4']
                };

                this.$store.commit('join/setTerms', terms);
            }
            catch(error)
            {
                console.log(error);
                this.getConfirmSet('alert', '에러', '이용약관 내용 불러오기에 실패하였습니다. 지속될 경우 관리자에게 문의하세요.');
            }
        },
        getConfirmResult() {
            this.confirmStat = false;

            if(this.certified === 'Y')
            {
                if( this.$route.query.type == 'O'){
                    this.$router.push({ path: '/join', query: { type: 'O' } });
                }
                else
                {
                    this.$router.push({ path: '/join', query: { type: 'I' } });
                }
               
            }
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getTerms(id) {
            this.popStat = true;
            this.contents = this.terms[id];
        },
        getCheckStat(id) {
            if(id === 'all')
            {
                if(this.getAgreeCheck() && this.agree['choice'] === 'Y')
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }
            else
            {
                return this.agree[id] === 'Y' ? true : false;
            }
        },
        getAllCheck() {
            const checkStat = event.target.checked;
            const list      = document.getElementsByName('agree');
            
            list.forEach((item)=>{
                item.checked = checkStat;

                this.$store.commit('join/setAgree', { gb : 'all', value : checkStat ? 'Y' : 'N' });
            });

            this.getClassChange();
        },
        getCheckbox() {
            const id        = event.target.id;
            const checkStat = event.target.checked;

            this.$store.commit('join/setAgree', { gb : id, value : checkStat ? 'Y' : 'N'});
            this.getClassChange();
        },
        getClassChange(){
            if(this.getAgreeCheck())
            {
                this.btnClass = 'blue';
            }
            else
            {
                this.btnClass = 'grey';
            }
        },
        getAgreeCheck(){
            return !(this.agree['age'] === 'N' || this.agree['use'] === 'N' || this.agree['info'] === 'N');
        },
        getAgreeNext() {
            if(this.getAgreeCheck())
            {
                getCertified().then(result => {
                    if(result.status)
                    {
                        this.getConfirmSet('alert', '성공', result.msg);
                        this.$store.commit('join/setJoinInfo', { gb : 'certified', value : 'Y' });
                    }
                    else
                    {
                        this.getConfirmSet('alert', '실패', result.msg);
                    }
                }).catch(error => {
                    console.log(error);
                    this.getConfirmSet('alert', '에러', error.msg);
                });
            }
            else
            {
                this.getConfirmSet('alert', '확인', '필수 약관을 모두 동의해주세요.');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.join {
    &-container {
        padding: 13px;

        h2 {
            font-size: $font20;
            font-weight: 700;
            color: $text-color-lv-2;
            margin-bottom: 15px;

            span {
                display: block;
                color: $blue-color-lv-4;
            }

            & + p {
                font-size: $font10;
                font-weight: 700;
                color: $line-color-lv-1;
                margin-bottom: 27px;
            }
        }

        .border {
            display: block;
            width: 100%;
            height: 1px;
            background-color: $line-color-lv-1;
            margin: 4px 0;
        }

        .bottom {
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 8px 16px 14px 16px;
            @include shadow2;
            width: 100%;
            background-color: $bk-color-lv-3;
            z-index: 2;
        }
    }
}
</style>