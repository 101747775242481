import { getAxiosData } from "@/assets/js/function";
import store from "@/store";
import { useRouter } from "vue-router";

/**
 * @description 강사 평점 관리
 */
export async function getGradeInfo(params) 
{
    const router = useRouter();

    try 
    {
        const res = await getAxiosData("https://data.wecls.com/inst/dashboard/rateList", params);

        console.log(res);

        /** 점수 및 카운트 헤더 */
        const info = [
            { title : '전체 리뷰수', count : res.data['cnt']['totalCnt'] },
            { title : '평균 평점', count : res.data['cnt']['rateAvg'] },
            { title : '최고 평점', count : res.data['cnt']['maxRate'] },
            { title : '최소 평점', count : res.data['cnt']['minRate'] },
            { title : '미답글', count : res.data['cnt']['unanswered'] }
        ];

        store.commit("grade/setInfo", info);

        const list = [];

        res.data['list'].map((item) => {
            const dataList = {
                irCd        : item.irCd,
                lcCd        : item.lcCd,
                reviewText  : item.repMemo ? item.repMemo : '',
                regDt       : item.regDt ? item.regDt.slice(0, -3) : '',
                writer      : item.writer,
                rate        : item.rate,
                status      : item.status,
                subject     : item.subject,
                memo        : item.memo
            };

            list.push(dataList);
        });

        store.commit("grade/setList", list);
    } 
    catch (error) 
    {
        console.error(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'inst');
            router.push('/login');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}
