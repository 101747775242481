<template>
    <div class="grade admin-div">
        <div class="inner section">
            <NoticeBox :lists="noticeLists" showTitle title="강의 관리"/>
            <SortListCard :sorts="headers" class="margin"/>
            <GreySelectBox class="margin">
                <Select :selectId="'type'" :selectOptions="dateType" :selectVal="search['type']" :selectChange="getSelectSet" class="small" :labelText="'기간 구분'" />
                &nbsp;&nbsp;
                <DateBox class="regular" :inputId="'startDt'" :inputValue="search['startDt']" :selectFunction="getSelectDate" :timePicker="false" />
                &nbsp;&nbsp;
                <DateBox class="regular" :inputId="'endDt'" :inputValue="search['endDt']" :selectFunction="getSelectDate" :timePicker="false" />
            </GreySelectBox>
            <FilterBox :filters="filters" class="margin" @filter-changed="getListChange" />
            <BasicTable :columns="columns" :rows="list" class="margin" @button-click="getButton"/>
        </div>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import SortListCard from "@/components/card/SortListCard.vue";
import Select from "@/components/input/Select.vue";
import NoticeBox from "@/components/common/NoticeBox.vue";
import GreySelectBox from "@/components/common/GreySelectBox.vue";
import BasicTable from "@/components/table/BasicTable.vue"
import DateBox from "@/components/input/DateBox.vue";
import FilterBox from "@/components/common/FilterBox.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from "vuex";
import { getDate, getConvertDate, getAxiosData } from "@/assets/js/function";
import { getLectMngList } from "@/assets/js/admin/lectMng";

export default {
    name: "AdmLectMng",
    components: {BasicTable, GreySelectBox, NoticeBox, Select, DateBox, FilterBox, SortListCard, Confirm},
    data() {
        return {
            noticeLists: [
                {content: "강사를 최종 선발 완료한 이후 사용가능하며, 강의가 진행된 이후 강의완료 처리를 해주셔야 합니다."},
                {content: "강사 또는 기관의 사정으로 강의가 취소된 경우 취소처리로 해주세요."},
                {content: "최종 선발된 강사가 모든 강의를 진행한 경우 진행완료로 처리 해주세요."}
            ],
            columns    : [
                {key: "regDt", header: "매칭일시", class: "w13"},
                {key: "lectStart", header: "강의 시작일", class: "w6"},
                {key: "lectEnd", header: "강의 마감일", class: "w6"},
                {key: "name", header: "강사", class: "w6"},
                {key: "subject", header: "강의명", class: "w28 blue"},
                {key: "amt", header: "강의 금액", class: "w6"},
                {key: "state", header: "완료 여부", class: "w6", type : { gubun : 'button', text : '강의 완료' }},
            ],
            resultYn            : 'N',
            approval            : 'N',
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            lcCd                : '',
            mtCd                : ''
        }
    },
    created() {
        this.getCheck()
    },
    computed : {
        ...mapState('data', {
            dateType  : state => state.data.dateType,
        }),
        ...mapState('admLectMng', {
            search  : state => state.admLectMng.search,
            headers : state => state.admLectMng.headers,
            filters : state => state.admLectMng.filters,
            allList : state => state.admLectMng.allList,
            list    : state => state.admLectMng.list
        })
    },
    methods : {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.approval === 'Y')
            {
                this.getLectResult();
            }

            if(this.resultYn === 'Y')
            {
                this.getCheck();
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getCheck(){
            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                start   : 0,
                limit   : 50
            };

            getLectMngList(params);
        },
        getSelectSet(id, event) {
            this.search[id] = event;

            const value = event['value'];
            const date  = getDate(value);

            this.search['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
            this.search['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');

            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                start   : 0,
                limit   : 50
            };

            getLectMngList(params);
        },
        getSelectDate(date, id) {
            this.search[id] = date;

            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                start   : 0,
                limit   : 50
            }

            getLectMngList(params);
        },
        getListChange(params) {
            if(params['title'] === '전체')
            {
                this.$store.commit('admLectMng/setList', this.allList);
            }
            else
            {
                const list = this.allList.filter((item) => {
                    let compYn = 'E';

                    if(params['title'] === '완료 강의')
                    {
                        compYn = 'Y';
                    }

                    return item.compYn === compYn;
                });

                this.$store.commit('admLectMng/setList', list);
            }
        },
        getButton(params) {
            console.log(params);
            this.lcCd     = params['lcCd'];
            this.mtCd     = params['mtCd'];
            this.approval = 'Y';

            this.getConfirmSet('confirm', '강의 완료 처리', '해당 강의를 완료처리하시겠습니까?');
        },
        async getLectResult() {
            try
            {
                const params = {
                    lcCd : this.lcCd,
                    mtCd : this.mtCd
                };

                const res = await getAxiosData('https://data.wecls.com/organ/dashboard/lectCompProc', params);

                if(res.status === 200)
                {
                    this.resultYn = 'Y';
                    this.approval = 'N';
                    this.getConfirmSet('alert', '완료', '완료 처리되었습니다.');
                }
            }
            catch(error)
            {
                console.log(error);
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout', 'organ');
                    this.$router.push('/organLogin');
                }
                else
                {
                    alert('완료 처리 중 에러가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                    this.resultYn = 'Y';
                    this.approval = 'N';
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.grade {
    padding: 90px 0;

    .margin {
        margin-top: 46px;
    }
}

.alert {
    .alert-content {
        text-align: center;
        font-size: $font12;
        font-weight: 400;
        color: $text-color-lv-1;
    }

    textarea{
        padding: 14px;
        border-radius: 4px;
        border: 1px solid $line-color-lv-2;
        background-color: $bk-color-lv-3;
        width: 300px;
        height: 120px;
        resize: none;
        margin-bottom: 25px;
        font-size: $font14;
        font-weight: 400;
        color: $text-color-lv-1;
    }
}
</style>