<template>
    <div class="payment admin-div">
        <div class="inner section">
            <div>
                <NoticeBox :lists="noticeLists" showTitle title="결제내역 조회"/>
                <!-- <RoundButton buttonText="정산 신청하기"/> -->
            </div>
            <SortListCard :sorts="headers" class="margin"/>
            <GreySelectBox class="margin">
                <Select :selectId="'type'" :selectOptions="dataType" :selectVal="search['type']" :selectChange="getSelectSet" class="small" :labelText="'기간 구분'" />
                &nbsp;
                <DateBox class="regular" :inputId="'startDt'" :inputValue="search['startDt']" :selectFunction="getSelectDate" :timePicker="false" />
                &nbsp;&nbsp;
                <DateBox class="regular" :inputId="'endDt'" :inputValue="search['endDt']" :selectFunction="getSelectDate" :timePicker="false" />
            </GreySelectBox>
            <FilterBox :filters="filters" class="margin" @filter-changed="getListChange"/>
            <BasicTable :columns="columns" :rows="list" class="margin" @button-click="getButtonClick"  @row-click="getListClick"/>
        </div>
    </div>

    <WhiteAlert buttonText="승인" title="데이페이 승인" v-if="showAlert">
        <p class="alert-content">
            데이페이를 승인하시면, (주) 위클즈에서 강사님에게 먼저 정산을 진행합니다. 강의에 문제가 없고 정상적으로 진행되었다면, 데이페이를 승인해주시기 바랍니다.
        </p>
    </WhiteAlert>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import SortListCard from "@/components/card/SortListCard.vue";
import Select from "@/components/input/Select.vue";
import FilterBox from "@/components/common/FilterBox.vue";
import NoticeBox from "@/components/common/NoticeBox.vue";
import GreySelectBox from "@/components/common/GreySelectBox.vue";
import BasicTable from "@/components/table/BasicTable.vue";
// import RoundButton from "@/components/button/RoundButton.vue";
import WhiteAlert from "@/components/alert/WhiteAlert.vue";
import DateBox from "@/components/input/DateBox.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from "vuex";
import { getPaymentList } from "@/assets/js/admin/payment";
import { getAxiosData ,getDate, getConvertDate } from "@/assets/js/function";

export default {
    name      : "AdmPaymentMng",
    components: {WhiteAlert, Select, BasicTable, GreySelectBox, DateBox, NoticeBox, FilterBox, SortListCard, Confirm},
    props     : {
        showAlert: Boolean,
    },
    data() {
        return {
            noticeLists: [
                {content: "강의를 진행해 주셔서 감사합니다. 홍길동 강사님의 최근 5년 이내 입금 내역을 조회 가능하며, 관련 내용에 대한 문의가 있을 경우 언제든지 연락주시기 바랍니다."},
                {content: "강의 비용에 대해서는 강의 금액에 세금계산서 또는 현금영수증을 발행해 드리고 있습니다. 발행에 필요한 정보나 요청사항이 있으시면 미리 알려주시기 바랍니다."},
                {content: "강의가 완료된 후 2주일 이내에 정산이 이루어지며 관련 내용에 대한 문의가 있을 경우 언제든지 연락주시기 바랍니다."},
                {content: "강의료 정산 시, 정확한 은행 정보를 기재해주셔야 원활한 입금이 가능합니다. 강의료 정산 관련하여 어떠한 문제가 있으시면 곧바로 연락 부탁드립니다."},
            ],
            columns: [
                {key: "status", header: "상태", class: "w8"},
                {key: "lectPeriod", header: "강의일", class: "w13"},
                {key: "name", header: "강사", class: "w8"},
                {key: "subject", header: "강의명", class: "w23 blue"},
                {key: "amt", header: "결제 금액", class: "w8"},
                {key: "receipDt", header: "결제 요청일", class: "w8"},
                {key: "payDay", header: "결제일", class: "w8"},
                {key: "payGb", header: "결제 수단", class: "w8"},
                {key: "payDoc", header: "증빙서류", class: "w8"},
                {key: "calcGb", header: "데이페이", class: "w8", type : { gubun : 'button', text : '승인' }},
            ],
            calcCd              : '',
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            resultYn            : 'N',
            approval            : 'N'
        }
    },
    created() {
        this.getCheck();
    },
    computed: {
        ...mapState('data', {
            dataType : state => state.data.dateType
        }),
        ...mapState('payment', {
            headers : state => state.payment.headers,
            search  : state => state.payment.search,
            filters : state => state.payment.filters,
            allList : state => state.payment.allList,
            list    : state => state.payment.list
        })
    },
    methods: {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.approval === 'Y')
            {
                this.getDayPayResult();
            }

            if(this.resultYn === 'Y')
            {
                this.getCheck();
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getSelectSet(id, event) {
            this.search[id] = event;

            const value = event['value'];
            const date  = getDate(value);

            this.search['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
            this.search['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');

            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt']
            };

            getPaymentList(params);
        },
        getSelectDate(date, id) {
            this.search[id] = date;

            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt']
            };

            getPaymentList(params);
        },
        getListChange(params) {
            if(params['title'] === '전체')
            {
                this.$store.commit('payment/setList', this.allList);
            }
            else
            {
                const list = this.allList.filter((item) => {
                    return item.status === params['title'];
                });

                this.$store.commit('payment/setList', list);
            }
        },
        getListClick(params) {
            this.$router.push({ path: `/admin/paymentDetail/${params['mtCd']}` });
        },
        getButtonClick(data) {
            this.calcCd   = data['calcCd'];
            this.approval = 'Y';

            this.getConfirmSet('confirm', '데이페이', '데이페이를 승인하시면, (주) 위클즈에서 강사님에게 먼저 정산을 진행합니다. 강의에 문제가 없고 정상적으로 진행되었다면, 데이페이를 승인해주시기 바랍니다.');
        },
        async getDayPayResult() {
            try
            {
                const params = {
                    calcCd : this.calcCd,
                    state  : 'Y'
                };

                const res = await getAxiosData('https://data.wecls.com/organ/dashboard/updateDayPay', params);

                if(res.status === 200)
                {
                    this.resultYn = 'Y';
                    this.approval = 'N';
                    this.getConfirmSet('alert', '승인', '데이페이가 승인되었습니다.');
                }
            }
            catch(error)
            {
                console.log(error);
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout', 'organ');
                    this.$router.push('/organLogin');
                }
                else
                {
                    alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
                    history.back();
                }
            }
        },
        getCheck() {
            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt']
            };

            getPaymentList(params);
        }
    }
}
</script>

<style lang="scss" scoped>
.payment {
    padding: 90px 0;

    .section {
        div {
            position: relative;

            button {
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }

    .margin {
        margin-top: 46px;
    }
}

.alert {
    .alert-content {
        text-align: justify;
        margin-bottom: 25px;
        font-size: $font12;
        font-weight: 400;
    }
}
</style>