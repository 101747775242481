/**
 * @description 메인 리스트 vuex 모듈 관련
 * @author 정해원, @date 2023-11-29
 */
const mainModule = {
    namespaced  : true,
    state       : {
        main : {
            list        : {
                bestList : [],
                mainList : [],
                likeList : []
            },
            moreList  : [],
            info      : getMainInfo()
        }
    },
    mutations : {
        setList(state, newList)
        {
            state.main.list = newList;
        },
        setMoreList(state, newMoreList)
        {
            state.main.moreList = newMoreList;
        },
        setInfo(state, newInfo)
        {
            state.main.info = newInfo;
        }
    }
};

function getMainInfo()
{
    return {
        fileNm      : '',
        subject     : '',
        dDay        : '',
        gubun       : '',
        regDt       : '',
        fieldCd     : '',
        lectPeriod  : '',
        lectTime    : '',
        totalTime   : '',
        amt         : '',
        estAmt      : '',
        area1       : '',
        area2       : '',
        target      : '',
        addr        : '',
        lectEq      : '',
        keyword     : '',
        regPeriod   : '',
        lectMemo    : '',
        reqDoc      : '',
        etcDoc      : '',
        fileList    : [],
        ogNm        : '',
        ogAddr      : '',
        chEmail     : '',
        chPerNm     : '',
        chPerTel    : ''
    }
}
  
export default mainModule;