<template>
    <div class="user admin-div">
        <div class="inner section">
            <div>
                <h2 class="notice-title">
                    공고제목: 놀이로 배우는 유아교육의 비밀
                </h2>
                <RoundButton buttonText="지원자 전형 이동" @click="getPopup"/>
            </div>

            <FilterBox :filters="cnt" class="margin filter" @filter-changed="getListChange" />

            <div class="select-wrapper margin">
                <Select :selectId="'area'" :selectOptions="getArea" :selectVal="search['area']" :selectChange="getSelectSet" class="small" :labelText="'지역'"/>
                <Select :selectId="'career'" :selectOptions="getCareer" :selectVal="search['career']" :selectChange="getSelectSet" class="small" :labelText="'경력'"/>
                <Select :selectId="'lecture'" :selectOptions="getLecture" :selectVal="search['lecture']" :selectChange="getSelectSet" class="small" :labelText="'강의분야'"/>
                <Select :selectId="'order'" :selectOptions="order" :selectVal="search['order']" :selectChange="getSelectSet" class="small" :labelText="'등록순서'"/>
            </div>

            <div class="card-wrapper">
                <UserDetailCard :list="list"/>
            </div>
        </div>
    </div>
    <WhiteAlert v-if="popStat" title="이동할 전형 선택" buttonText="이동" @close-popup="popStat = !popStat" @confirm-review="getMoveVol">
        <label for="chkD">
            <input type="checkbox" :id="'chkD'" :name="'stat'" :value="'D'" @change="getCheckStat"/>
            서류 통과
        </label>
        <label for="chkI">
            <input type="checkbox" :id="'chkI'" :name="'stat'" :value="'I'" @change="getCheckStat"/>
            2차 검증
        </label>
        <label for="chkY">
            <input type="checkbox" :id="'chkY'" :name="'stat'" :value="'Y'" @change="getCheckStat"/>
            최종 매칭
        </label>

        <p class="mt-25"></p>
    </WhiteAlert>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmResult"/>
</template>

<script>
import Select from "@/components/input/Select.vue";
import UserDetailCard from "@/views/admin/template/UserDetailCard.vue";
import FilterBox from "@/components/common/FilterBox.vue";
import RoundButton from "@/components/button/RoundButton.vue";
import WhiteAlert from "@/components/alert/WhiteAlert.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { getUserMngDetailList } from '@/assets/js/admin/userMngDetail';
import { mapState } from 'vuex';
import { getAxiosData } from '@/assets/js/function'

export default {
    name      : "AdmUserDetail",
    components: {RoundButton, FilterBox, WhiteAlert, UserDetailCard, Select, Confirm},
    computed: {
        ...mapState('data', {
            area    : state => state.data.area1,
            career  : state => state.data.career,
            lecture : state => state.data.lecture
        }),
        ...mapState('userMngDetail', {
            search  : state => state.userMngDetail.search,
            cnt     : state => state.userMngDetail.cnt,
            itCd    : state => state.userMngDetail.itCd,
            allList : state => state.userMngDetail.allList,
            list    : state => state.userMngDetail.list,
        }),
        getArea() {
            return [{ name: '전체', value: '' }, ...this.area];
        },
        getCareer() {
            return [{ name: '전체', value: '' }, ...this.career];
        },
        getLecture() {
            return [{ name: '전체', value: '' }, ...this.lecture];
        }
    },
    created() {
        this.getUserMngDetailList();
    },
    data() {
        return {
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            order               : [
                { name : '오름차순', value : 'ASC' },
                { name : '내림차순', value : 'DESC' }
            ],
            popStat             : false,
            state               : ''
        }
    },
    methods: {
        getConfirmResult() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getPopup() {
            this.state   = '';
            this.popStat = !this.popStat;
        },
        getUserMngDetailList() {
            const params = {
                lcCd    : this.$route.params.lcCd,
                area    : this.search['area']['value'],
                career  : this.search['career']['value'],
                fieldCd : this.search['lecture']['value'],
                order   : this.search['order']['value'],
                start   : 0,
                limit   : 50
            };

            getUserMngDetailList(params);
        },
        getSelectSet(id, event) {
            this.search[id] = event;

            const params = {
                lcCd    : this.$route.params.lcCd,
                area    : this.search['area']['value'],
                career  : this.search['career']['value'],
                fieldCd : this.search['lecture']['value'],
                order   : this.search['order']['value'],
                start   : 0,
                limit   : 50
            };

            getUserMngDetailList(params);
        },
        getListChange(params) {
            if(params['title'] === '전체')
            {
                this.$store.commit('userMngDetail/setList', this.allList);
            }
            else
            {
                const list = this.allList.filter((item) => {
                    return item.stNm === params['title'];
                });

                this.$store.commit('userMngDetail/setList', list);
            }
        },
        getCheckStat() {
            const id        = event.target.id;
            const value     = event.target.value;
            const list      = document.getElementsByName('stat');
            const checkbox  = document.getElementById(id);

            list.forEach((item) => {
                item.checked = false;
            });

            checkbox.checked = true;
            this.state = value;
        },
        async getMoveVol() {
            if(this.state === '')
            {
                this.getConfirmSet('alert', '확인', '이동할 전형을 선택해주세요.');
                return false;
            }

            if(this.itCd.length === 0)
            {
                this.getConfirmSet('alert', '확인', '강사를 선택해주세요.');
                return false;
            }

            if(this.state === 'Y')
            {
                if(this.itCd.length > 1)
                {
                    this.getConfirmSet('alert', '확인', '최종 매칭은 1명만 가능합니다.');
                    return false;
                }
            }

            try
            {
                const params = {
                    lcCd    : this.$route.params.lcCd,
                    st      : this.state,
                    itArr   : this.itCd
                };

                const res = await getAxiosData('https://data.wecls.com/inst/mainList/moveVol', params);

                if(res.status === 200)
                {
                    this.$store.commit('userMngDetail/setItCdReset');
                    this.getPopup();
                    this.getConfirmSet('alert', '완료', '이동 완료되었습니다.');

                    const params = {
                        lcCd    : this.$route.params.lcCd,
                        area    : this.search['area']['value'],
                        career  : this.search['career']['value'],
                        fieldCd : this.search['lecture']['value'],
                        order   : this.search['order']['value'],
                        start   : 0,
                        limit   : 50
                    };

                    getUserMngDetailList(params);

                    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      
                    checkboxes.forEach((checkbox) => {
                        checkbox.checked = false;
                    });
                }
            }
            catch(error)
            {
                console.log(error);
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout', 'organ');
                    this.$router.push('/organLogin');
                }
                else if(error.response.data.status === 400)
                {
                    this.getConfirmSet('alert', '확인', error.response.data.data);
                }
                else
                {
                    alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
                    history.back();
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.user {
    padding: 90px 0;

    .section {
        div {
            position: relative;

            button {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        .filter{
            border-bottom: 1px solid $line-color-lv-4;
        }
    }

    .margin {
        margin-top: 40px;
    }

    .select-wrapper {
        display: flex;
        padding-bottom: 24px;
        border-bottom: 1px solid $line-color-lv-3;
        margin-bottom: 30px;

        .select + .select {
            margin-left: 10px;
        }
    }

    .notice-title {
        font-size: $font28;
        font-weight: 700;
        margin-bottom: 30px;
    }

    .card-wrapper {
        padding: 10px 10px 100px 10px;
        border-radius: 8px;
        background-color: $bk-color-lv-2;
    }
}

.alert{
    &-wrapper{
        label{
            padding: 10px;
            border-radius: 4px;
            border: 1px solid $line-color-lv-2;
            display: flex;
            text-align: left;
            font-size: $font14;
            cursor: pointer;

            input[type="checkbox"]{
                width: 16px;
                height: 16px;
                margin-top: 2px;
                margin-right: 10px;
            }
        }

        label + label{
            margin-top: 10px;
        }
    }
}
</style>