import { getDate, getConvertDate } from "@/assets/js/function";

const date = getDate('mon');
/**
 * @description 기관 유저 상세  vuex 모듈 관련
 * @author 정해원, @date 2023-11-29 / @author 김원명, @date 2023-12-20 수정
 */

const userMngModule = {
    namespaced: true,
    state: {
        userMng: {
            search : {
                type    : { name : '이달', value : 'mon' },
                startDt : getConvertDate(date['st'], 'yyyy-mm-dd'),
                endDt   : getConvertDate(date['et'], 'yyyy-mm-dd')
            },
            cnt    : [
                { title : '대기', count : 0 },
                { title : '진행중', count : 0 },
                { title : '마감완료', count : 0 },
                { title : '반려', count : 0 },
                { title : '전체', count : 0 }
            ],
            allList : [],
            list    : []
        }
    },
    mutations: {
        setCnt(state, newCnt)
        {
            state.userMng.cnt = newCnt
        },
        setAllList(state, newAllList)
        {
            state.userMng.allList = newAllList;
            state.userMng.list    = newAllList;
        },
        setList(state, newList)
        {
            state.userMng.list = newList;
        }
    },
};


export default userMngModule;
