<template>
    <div class="match">
        <MypageHeader />
        <div class="inner section">
            <NoticeBox :lists="noticeLists" showTitle title="매칭 관리" />
            <SortListCard :sorts="info" class="margin" />
            <GreySelectBox class="margin">
                <Select :selectId="'type'" :selectOptions="dateType" :selectVal="search['type']" :selectChange="getSelectSet" class="small" :labelText="'기간 구분'" />
                &nbsp;&nbsp;
                <DateBox class="regular" :inputId="'startDt'" :inputValue="search['startDt']" :selectFunction="getSelectDate" :timePicker="false" />
                &nbsp;&nbsp;
                <DateBox class="regular" :inputId="'endDt'" :inputValue="search['endDt']" :selectFunction="getSelectDate" :timePicker="false" />
            </GreySelectBox>
            <FilterBox :filters="cnt" class="margin" @filter-changed="getListChange" />
            <BasicTable :columns="columns" :rows="list" class="margin" @row-click="getListClick"/>
        </div>
    </div>
</template>

<script>
import NoticeBox from "@/components/common/NoticeBox.vue";
import Select from "@/components/input/Select.vue";
import SortListCard from "@/components/card/SortListCard.vue";
import BasicTable from "@/components/table/BasicTable.vue";
import MypageHeader from "@/views/user/mypage/MypageHeader.vue";
import GreySelectBox from "@/components/common/GreySelectBox.vue";
import FilterBox from "@/components/common/FilterBox.vue";
import DateBox from "@/components/input/DateBox.vue";

import { getDate, getConvertDate } from "@/assets/js/function";
import { mapState } from "vuex";
import { getMatchingInfo } from "@/assets/js/user/matching"

export default {
    name: "MatchMng",
    components: { Select, FilterBox, GreySelectBox, MypageHeader, DateBox, BasicTable, SortListCard, NoticeBox },
    data() {
        return {
            noticeLists: [
                { content: "강의 신청서를 접수하신 경우, 기관의 요청사항에 늦지 않게 대응해주시기 바랍니다." },
                { content: "매칭이 이루어지면, 즉시 기관과의 연락 수단을 공 유해드립니다. 이후 학생과의 원활한 소통과 스케줄 조율에 신속하게 응대해주세요." },
                { content: "매칭이 완료된 후, 첫 수업 전 기관에게 간단한 소개와 수업 준비 사항에 대한 안내를 드리는 것이 좋습니다." },
                { content: "매칭된 기관과의 관계를 존중하고 신뢰를 기반으로 강의를 진행해주세요. 상호 존중과 이해를 바탕을 원활한 수업이 이뤄지도록 노력해주시길 부탁드립니다." },
            ],
            columns: [
                { key: "stNm", header: "매칭 상태", class: "w8" },
                { key: "regDt", header: "매칭 시도일시", class: "w14" },
                { key: "ogNm", header: "기관명", class: "w27" },
                { key: "subject", header: "강의명", class: "w27 blue" },
                { key: "lectPeriod", header: "매칭시 수업일자", class: "w14" },
                { key: "amt", header: "금액", class: "w9" }
            ]
        }
    },
    created() {
        this.getStack();
    },
    computed: {
        ...mapState('data', {
            dateType  : state => state.data.dateType,
        }),
        ...mapState('match', {
            search  : state => state.matching.search,
            info    : state => state.matching.info,
            cnt     : state => state.matching.cnt,
            allList : state => state.matching.allList,
            list    : state => state.matching.list
        })
    },
    methods: {
        getStack() {
            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                st      : ''
            };

            getMatchingInfo(params);
        },
        getSelectSet(id, event) {
            this.search[id] = event;

            const value = event['value'];
            const date  = getDate(value);

            this.search['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
            this.search['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');

            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
            };

            getMatchingInfo(params);
        },
        getSelectDate(date, id) {
            this.search[id] = date;

            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                st      : ''
            }

            getMatchingInfo(params);
        },
        getListClick(data) {
            this.$router.push({ path: '/user/main/detail', query: { lpCd: data['lpCd'] } });
        },
        getListChange(params) {
            const list = [];

            if(params['state'] === '')
            {
                this.$store.commit('match/setList', this.allList);
            }
            else
            {
                this.allList.map((item) => {
                    switch(params['state'])
                    {
                        case 'E': case 'Y': case 'N':
                            if(params['state'] === item.state)
                            {
                                list.push(this.getListData(item));
                            }
                        break;
                        case 'I':
                            if(item.state === 'I' || item.state === 'D')
                            {
                                list.push(this.getListData(item));
                            }
                        break;
                    }
                });

                this.$store.commit('match/setList', list);
            }
        },
        getListData(item) {
            return {
                matchSt     : item.matchSt,
                stNm        : item.stNm,
                regDt       : item.regDt,
                ogNm        : item.ogNm,
                subject     : item.subject,
                lectPeriod  : item.lectPeriod,
                amt         : item.amt
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.match {
    padding: 90px 0;

    .margin {
        margin-top: 46px;
    }
}
</style>