<template>
    <div class="review">
        <div class="review-wrapper" v-for="(review, index) in reviews" :key="index">
            <img src="../../assets/images/icon_talk_blue.png" title="말풍선 아이콘" alt="말풍선 아이콘"/>
            <h2>
                {{ review.title }}
            </h2>
            <p>
                {{ review.description }}
            </p>
            <div class="flex">
                <h5>
                    {{ review.location }}
                    <p>
                        {{ review.person }}
                    </p>
                </h5>
                <img class="person-icon" :src="require(`@/assets/images/person_${index + 1}.png`)" title="사람 아이콘" alt="사람 아이콘"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "ReviewTemplate",
    props: {
        reviews: Array,
    },
}
</script>

<style lang="scss" scoped>
.review {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    &-wrapper {
        background-color: $bk-color-lv-3;
        border-radius: 6px;
        @include shadow8;
        padding: 30px 40px;
        width: 32%;
        height: 390px;
        text-align: left;
        position: relative;
        margin-bottom: 24px;
        transition: background-color .2s ease-in;

        h2 {
            font-size: $font24;
            margin: 18px 0;
        }

        & > p {
            font-size: $font16;
            font-weight: 400;
            line-height: 22px;
        }

        .flex{
            align-items: center;
            position: absolute;
            left: 0;
            bottom: 20px;
            justify-content: space-between;
            padding: 0 25px 0 40px;

            h5{
                color: #737373;
                font-size: 1rem;

                p{
                    color: #8E8E8E;
                    font-weight: 400;
                    font-size: .875rem;
                }
            }

            .person-icon {
                width: 85px;
            }
        }
    }
}
</style>