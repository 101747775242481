import { getAxiosData, getCommas } from "@/assets/js/function";
import store from "@/store";
import { useRouter } from "vue-router";

/**
 * @description 기관 강사 모집 시 기업 정보
 */
export async function getCollectInfo()
{
    const router = useRouter();

    try 
    {
        const res  = await getAxiosData("https://data.wecls.com/organ/lectInfo/getInfo");

        console.log(res);

        store.commit('collect/setBase', {gb : 'ogNm', value : res.data['ogNm']});
        store.commit('collect/setBase', {gb : 'location', value : res.data['ogAddr']});
    }
    catch (error) 
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'organ');
            router.push('/organLogin');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}

/**
 * @description 강의 수정 시 상세 정보
 */
export async function getCollectDetailInfo(params)
{
    const router = useRouter();

    try 
    {
        const res  = await getAxiosData("https://data.wecls.com/organ/mainList/getMyLect", params);

        console.log(res);

        const [startDate, endDate]   = res.data['lectPeriod'].split(' ~ ');
        const [startTime, endTIme]   = res.data['lectTime'].split(' ~ ');
        const [startRegDt, endRegDt] = res.data['regPeriod'].split(' ~ ');

        const fileOther = [];

        if(res.data['file'].length === 0)
        {
            fileOther.push({
                text : '',
                file : null,
                gb   : 'N'
            });
        }
        else
        {
            res.data['file'].map((item) => {
                fileOther.push({
                    fileNo  : item.fileNo,
                    text    : item.fileNm,
                    file    : item.filePath,
                    gb      : 'Y'
                });
            })
        }

        const info = {
            lcCd     : res.data['lcCd'],
            img1            : {
                text : res.data['fileImg'] ? getFileCheck(res.data['fileImg'], '', '1', 'fileNm') : '',
                file : res.data['fileImg'] ? getFileCheck(res.data['fileImg'], null, '1', 'filePath') : null,
                gb   : res.data['fileImg'] ? 'Y' : 'N'
            },
            img2            : {
                text : res.data['fileImg'] ? getFileCheck(res.data['fileImg'], '', '2', 'fileNm') : '',
                file : res.data['fileImg'] ? getFileCheck(res.data['fileImg'], null, '2', 'filePath') : null,
                gb   : res.data['fileImg'] ? 'Y' : 'N'
            },
            img3            : {
                text : res.data['fileImg'] ? getFileCheck(res.data['fileImg'], '', '3', 'fileNm') : '',
                file : res.data['fileImg'] ? getFileCheck(res.data['fileImg'], null, '3', 'filePath') : null,
                gb   : res.data['fileImg'] ? 'Y' : 'N'
            },
            img4            : {
                text : res.data['fileImg'] ? getFileCheck(res.data['fileImg'], '', '4', 'fileNm') : '',
                file : res.data['fileImg'] ? getFileCheck(res.data['fileImg'], null, '4', 'filePath') : null,
                gb   : res.data['fileImg'] ? 'Y' : 'N'
            },
            subject         : res.data['subject'],
            fieldCd         : res.data['fieldCd'],
            lectGb          : res.data['lectGb'],
            startDt         : startDate,
            endDt           : endDate,
            startTime       : startTime,
            endTime         : endTIme,
            amt             : Number(res.data['amt']),
            lectRealDt      : res.data['lectRealDt'],
            lectRealTime    : res.data['lectRealTime'],
            totalTime       : res.data['totalTime'],
            estAmt          : getCommas(Number(res.data['estAmt'])),
            target          : res.data['target'],
            area1           : res.data['lectArea1'],
            area2           : res.data['lectArea2'],
            zip             : res.data['zip'],
            addr            : res.data['addr'],
            addrDetail      : res.data['addrDetail'],
            lectEq          : res.data['lectEq'],
            keyword         : res.data['keyword'],
            career          : res.data['career'],
            acadAbil        : res.data['acadAbil'],
            startRegDt      : startRegDt,
            endRegDt        : endRegDt,
            reqDoc          : res.data['reqDoc'],
            etcDoc          : res.data['etcDoc'],
            lectMemo        : res.data['lectMemo'],
            ogNm            : res.data['ogNm'],
            location        : res.data['ogAddr'],
            chEmail         : res.data['chEmail'],
            chPerTel        : res.data['chPerTel'],
            chPerNm         : res.data['chPerNm'],
            fileOther       : fileOther,
            fileGb          : [],
            delYn           : 'N'
        }

        console.log(info);

        store.commit('collect/setInfo', info);
    }
    catch (error) 
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'organ');
            router.push('/organLogin');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}

function getFileCheck(data, val, seq, nm)
{
    const check = data.find(item => item.fileNo === seq);

    if(check)
    {
        return check[nm];
    }
    else
    {
        return val;
    }
}