
<template>
    <div class="profile">
        <MypageHeader />
        <p class="border" />
        <div class="inner section">
            <NoticeBox :lists="noticeLists" showSubTitle subTitle="프로필 관리" class="blue2 no-list" />
            <ProfileTable/>
            <div class="button-wrapper">
                <BaseButton class="grey profile-button" @click="getSaveProfile('Y')" buttonText="임시저장" />
                <BaseButton class="profile-button" @click="getApproval" buttonText="승인 요청하기"/>
            </div>
        </div>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import MypageHeader from "@/views/user/mypage/MypageHeader.vue";
import NoticeBox from "@/components/common/NoticeBox.vue";
import ProfileTable from "@/views/user/mypage/template/ProfileTable.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import Confirm from "@/components/alert/Confirm.vue";
import axios from "axios";

import { mapState } from 'vuex';

export default {
    name: "ProfileMng",
    components: { BaseButton, ProfileTable, NoticeBox, MypageHeader, Confirm },
    data() {
        return {
            noticeLists: [
                { content: "매칭 강사 프로필 기관 담당자에게 강의 제안을 받을 수 있고 매칭 시 사용되는 기본 이력서입니다. 개인정보는 공개되지 않으니 안심하세요." },
            ],
            confirmStat : false,
            resultYn    : 'N',
            approval    : 'N'
        }
    },
    computed: {
        ...mapState('profile', {
            info    : state => state.profile.info
        })
    },
    methods: {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.approval === 'Y')
            {
                this.getSaveProfile('N');
            }

            if(this.resultYn === 'Y')
            {
                location.reload();
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
            this.approval    = 'N';
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getApproval() {
            this.getConfirmSet('confirm', '안내', '정보를 입력하신 후 승인요청하기를 눌러주세요. 관리자의 승인 이후에 다른 서비스 이용이 가능합니다.');

            this.approval = 'Y';
        },
        async getSaveProfile(tmpYn) {
            this.info['tmpYn'] = tmpYn;

            const formData = new FormData();

            formData.append('iaCd',         this.info['iaCd']);
            formData.append('fieldCd',      this.info['fieldCd']);
            formData.append('appeal',       this.info['appeal']);
            formData.append('emerTel',      this.info['emerTel']);
            formData.append('area1',        this.info['area1']);
            formData.append('area2',        ''); /** 미사용인데 임시로 넣어둠 */
            formData.append('prefDay',      JSON.stringify(this.info['prefDay']));
            formData.append('acadAbil',     this.info['acadAbil']);
            formData.append('school',       this.info['school']);
            formData.append('major',        this.info['major']);
            formData.append('lectField',    this.info['lectField']);
            formData.append('career',       this.info['career']);
            formData.append('repCareer',    JSON.stringify(this.info['repCareer']));
            formData.append('instMemo',     this.info['instMemo']);
            formData.append('careerMemo',   this.info['careerMemo']);
            formData.append('pubYn',        this.info['pubYn']);
            formData.append('tmpYn',        tmpYn);
            if(this.info['proofImg']['file'] !== null)
            {
                formData.append('img1', this.info['proofImg']['file']);
            }

            if (this.info['finalEduImg']['file'] !== null) 
            {
                formData.append('file1', this.info['finalEduImg']['file']);
            }

            if (this.info['credentialsImg']['file'] !== null) 
            {
                formData.append('file2', this.info['credentialsImg']['file']);
            }

            formData.append('delYn',    this.info['delYn']);

            if(this.info['fileGb'].length !== 0)
            {
                this.info['fileGb'].forEach((item, index) => {
                    formData.append(`fileGb[${index}]`, JSON.stringify(item));
                });
            }

            const fileOther = [];

            this.info['dataFile'].forEach((item) => {
                if(item.file !== null)
                {
                    fileOther.push(item.file);
                }
            });

            if(fileOther.length !== 0)
            {
                fileOther.forEach((data, index) => {
                    formData.append(`fileOther[${index}]`, data);
                });
            }

            // const formDataJson = {};

            // formData.forEach((value, key) => {
            //     formDataJson[key] = value;
            // });

            // console.log(formDataJson);

            try 
            {
                const instance = axios.create({
                    withCredentials : true,
                    headers : {
                        'X-Subdomain'  : window.location.hostname
                    }
                });
                
                const res = await instance.post('https://data.wecls.com/inst/registInfo/newData', formData);

                if(res.status === 200)
                {
                    this.resultYn = 'Y';
                    this.approval = 'N';
                    this.getConfirmSet('alert', '완료', (tmpYn === 'Y' ? '임시 저장되었습니다.' : '승인 요청되었습니다.'));
                }
                else
                {
                    this.getConfirmSet('alert', '에러', res.data.data);
                }
            } 
            catch (error) 
            {
                console.log(error);
                this.approval = 'N';
                
                if(error.response.data.code === 500)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout', 'inst');
                    this.$router.push('/login');
                }
                else 
                {
                    this.getConfirmSet('alert', '에러', error.response.data.data);
                }
            }
        },
    }
}

</script>

<style lang="scss" scoped>
.profile {
    padding: 90px 0;

    .margin {
        margin-top: 46px;
    }

    .button-wrapper {
        text-align: center;
        margin-top: 46px;

        .profile-button {
            padding: 10px;
            border-radius: 4px;
            width: 146px;
            display: inline-block;

            &.grey {
                margin-right: 16px;
            }
        }
    }
}
</style>