
<template>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import Confirm from "@/components/alert/Confirm.vue";

export default {
    name: "InfoMng",
    components: { Confirm },
    data() {
        return {
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : ''    
        }
    },
    created() {
        this.getConfirmSet('alert', '안내', '준비 중인 화면입니다.');
    },
    methods : {
        getConfirmResult() {
            this.confirmStat = false;
            this.$router.go(-1);
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        }
    }
}

</script>