<template>
    <div class="toss-wrapper">
        <div class="wrapper">
            <div class="box_section">
                <div id="payment-method"></div>     <!-- 결제 UI -->
                <div id="agreement"></div>          <!-- 이용약관 UI -->
                <div class="result">        <!-- 결제하기 버튼 -->
                    <button @click="requestPayment" class="button" id="payment-button">결제하기</button>
                    <button @click="closePayment" class="button grey" id="close-button">취소하기</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {loadPaymentWidget, ANONYMOUS} from "@tosspayments/payment-widget-sdk";
import {nanoid} from "nanoid";

export default {
    name : "tossPatments",
    props: {
        amount: Number
    },
    data() {
        return {
            paymentWidget      : null,
            paymentMethodWidget: null,
            clientKey          : "test_gck_XZYkKL4MrjdLavz6zdwA30zJwlEW",
            customerKey        : nanoid()
        };
    },
    methods: {
        async requestPayment() {
            try {
                if (this.paymentWidget) {
                    await this.paymentWidget.requestPayment({
                        orderId     : nanoid(),
                        orderName   : "위클즈 TEST",
                        customerName: "김토스",
                    }).then((data) => {
                        console.log(data);
                        this.$emit('get-result');
                    });
                }
            } catch (error) {
                console.error(error);
            }
        },
        closePayment() {
            this.$emit('get-close');
        }
    },
    async mounted() {
        // ------  결제위젯 초기화 ------
        this.paymentWidget = await loadPaymentWidget(this.clientKey, ANONYMOUS);

        // ------  결제 UI 렌더링 ------
        this.paymentMethodWidget = await this.paymentWidget.renderPaymentMethods("#payment-method", {value: this.amount}, {variantKey: "DEFAULT"});
        // ------  이용약관 UI 렌더링 ------
        this.paymentWidget.renderAgreement("#agreement", {variantKey: "AGREEMENT"});
    }
};
</script>

<style lang="scss" scoped>
.toss-wrapper {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 10;
}

.h4 {
    font-size: 20px;
    font-weight: 700;
    color: #333D4B;
}

.wrapper {
    max-width: 800px;
    margin: 0 auto;
}

.button {
    color: #fff;
    background-color: #3182f6;
    transition: background 0.2s ease, color 0.1s ease;
    border-radius: 8px;
    padding: 11px 16px;

    & + &{
        margin-left: 5px;
    }

    &.grey {
        color: $bk-color-lv-3;
        background-color: $line-color-lv-0;

        &:hover {
            background-color: $text-color-lv-5;
        }
    }

    &:hover {
        background-color: #1b64da;
    }
}

.title {
    margin: 0 0 4px;
    font-size: 24px;
    font-weight: 600;
    color: #4e5968;
}

.result {
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-wrap: balance;
}

.box_section {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 1%), 0 6px 6px rgb(0 0 0 / 6%);
    padding: 40px 30px 50px 30px;
    color: #333D4B
}

:root {
    --checkable-size: 20px;
    --checkable-input-top: 3px;
    --checkable-input-left: 5px;
    --checkable-input-width: 14px;
    --checkable-input-height: 10px;
    --checkable-input-svg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.343 4.574l4.243 4.243 7.07-7.071' fill='transparent' stroke-width='2' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    --checkable-label-text-padding: 8px;
    --indeterminate-checkable-input-top: 7px;
    --indeterminate-checkable-input-left: 5px;
    --indeterminate-checkable-input-width: 14px
}

:root .checkable--small {
    --checkable-size: 20px;
    --checkable-input-top: 2px;
    --checkable-input-left: 4px;
    --checkable-input-width: 12px;
    --checkable-input-height: 9px;
    --checkable-input-svg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.286 3.645l3.536 3.536 5.892-5.893' fill='transparent' stroke-width='2' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    --indeterminate-checkable-input-top: 5px;
    --indeterminate-checkable-input-left: 4px;
    --indeterminate-checkable-input-width: 12px
}

.checkable {
    position: relative;
    display: flex
}

.checkable + .checkable {
    margin-top: 12px
}

.checkable--inline {
    display: inline-block
}

.checkable--inline + .checkable--inline {
    margin-top: 0;
    margin-left: 18px
}

.checkable__label {
    display: inline-block;
    max-width: 100%;
    min-height: 20px;
    min-height: var(--checkable-size);
    line-height: 1.6;
    padding-left: 20px;
    padding-left: var(--checkable-size);
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #4e5968;
    color: var(--grey700);
    cursor: pointer
}

.checkable__input {
    position: absolute;
    margin: 0 0 0 -20px;
    margin: 0 0 0 calc(var(--checkable-size) * -1);
    top: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    cursor: pointer
}

.checkable__input:after, .checkable__input:before {
    content: "";
    position: absolute
}

.checkable__input:before {
    top: -4px;
    left: 0;
    width: 20px;
    width: var(--checkable-size);
    height: 20px;
    height: var(--checkable-size);
    border: 2px solid #d1d6db;
    border: 2px solid #d1d6db;
    background-color: #fff;
    background-color: white;
    transition: border-color .1s ease, background-color .1s ease
}

.checkable__input:after {
    opacity: 0;
    transition: opacity .1s ease;
    top: 3px;
    top: var(--checkable-input-top);
    left: 5px;
    left: var(--checkable-input-left);
    width: 14px;
    width: var(--checkable-input-width);
    height: 10px;
    height: var(--checkable-input-height);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.343 4.574l4.243 4.243 7.07-7.071' fill='transparent' stroke-width='2' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-image: var(--checkable-input-svg);
    background-repeat: no-repeat
}

.checkable__input[type=checkbox]:indeterminate:after {
    top: 7px;
    top: var(--indeterminate-checkable-input-top);
    left: 5px;
    left: var(--indeterminate-checkable-input-left);
    width: 14px;
    width: var(--indeterminate-checkable-input-width);
    height: 0;
    border: 1px solid #fff;
    border: 1px solid var(--white);
    border-radius: 1px;
    transform: rotate(0)
}

.checkable__input:focus {
    outline: 0
}

.checkable__input:focus:before, .checkable__input:hover:before {
    background-color: #e8f3ff;
    background-color: #e8f3ff;
    border-color: #3182f6;
    border-color: #3182f6
}

.checkable__input:checked:before, .checkable__input[type=checkbox]:indeterminate:before {
    border-color: #3182f6;
    border-color: #3182f6;
    background-color: #3182f6;
    background-color: #3182f6
}

.checkable__input:checked:after, .checkable__input[type=checkbox]:indeterminate:after {
    opacity: 1
}

.checkable__input:disabled:before {
    background-color: #f2f4f6;
    background-color: var(--grey100);
    border-color: rgba(0, 23, 51, .02);
    border-color: var(--greyOpacity50)
}

.checkable__input:disabled:checked:before, .checkable__input:disabled[type=checkbox]:indeterminate:before {
    background-color: #e5e8eb;
    background-color: var(--grey200);
    border-color: #e5e8eb;
    border-color: var(--grey200)
}

.checkable__input[type=checkbox]:before {
    border-radius: 6px
}

.checkable__input[type=radio]:before {
    border-radius: 12px
}

.checkable__label-text {
    display: inline-block;
    padding-left: 13px;
    color: #4e5968;

    /* padding-left: var(--checkable-label-text-padding) */
}

.checkable--disabled > .checkable__input {
    cursor: not-allowed
}

.checkable--disabled > .checkable__label {
    color: #b0b8c1;
    color: var(--grey400);
    cursor: not-allowed
}

.checkable--read-only {
    pointer-events: none
}
</style>