<template>
    <div class="join intro">
        <MobileBox title="회원가입" showPrev>
            <InputBox labelText="이메일" :inputId="'id'"
                :inputValue="login['id']['value']" :helperText="login['id']['text']" :class="login['id']['class']"
                placeholder="이메일을 입력해주세요." @input="getInputValSet"/>

            <InputBox labelText="비밀번호" :inputId="'pw'" :inputType="'password'"
                :inputValue="login['pw']['value']" :helperText="login['pw']['text']" :class="login['pw']['class']"
                placeholder="비밀번호를 입력해주세요" @input="getInputValSet"/>

            <InputBox labelText="비밀번호 확인" :inputId="'pwChk'" :inputType="'password'"
                :inputValue="login['pwChk']['value']" :helperText="login['pwChk']['text']" :class="login['pwChk']['class']"
                placeholder="비밀번호를 입력해주세요" @input="getInputValSet"/>

            <BaseButton class="grey" buttonText="회원가입" @click="getJoinNext" />
        </MobileBox>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import MobileBox from "@/components/common/MobileBox.vue";
import InputBox from "@/components/input/InputBox.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { getAxiosData } from "@/assets/js/function";
import { mapState } from 'vuex';

export default {
    name: "JoinFirst",
    components: {BaseButton, InputBox, MobileBox, Confirm},
    computed: {
        ...mapState('join', {
            certified   : state => state.joinInfo.certified,
            agree       : state => state.joinInfo.agree,
            login       : state => state.joinInfo.login,
            instrInfo   : state => state.joinInfo.instrInfo,
        })
    },
    data() {
        return {
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            joinComplete        : 'N'
        }
    },
    created() {
        this.getAgreeCheck();
    },
    methods: {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.joinComplete === 'Y')
            {
                this.$store.commit('join/setReset');

                if(this.$route.query.type === 'I')
                {
                    this.$router.push({path : `/login`});
                }
                else
                {
                    this.$router.push({path : `/organLogin`});                    
                }
            }

            if(this.confirmType === 'confirm')
            {
                this.getJoinComplete();
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getAgreeCheck() {
            if(this.certified === 'N')
            {
                this.$router.go(-1);
            }
        },
        getInputValSet(event) {
            const id    = event.target.id;
            const value = event.target.value;

            this.login[id]['value'] = value;
        },
        getInputCheck() {
            let stat = true;

            stat = stat && this.getValueCheck('id');
            stat = stat && this.getValueCheck('pw');
            stat = stat && this.getValueCheck('pwChk');
            stat = stat && this.getIdCheck();
            stat = stat && this.getPwCheck();
            stat = stat && this.getPwRecomm();

            return stat;
        },
        getValueCheck(id){
            let text = '필수 정보가 입력되지 않았습니다.';

            if(this.login[id]['value'] === '')
            {
                this.getErrorText(false, text, id);
                this.getInputFocus(id);
                return false;
            }
            else
            {
                this.getErrorText(true, text, id);
                return true;
            }
        },
        getIdCheck() {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const stat       = emailRegex.test(this.login['id']['value']);

            if(stat)
            {
                this.getErrorText(stat, '', 'id');
            }
            else
            {
                this.getErrorText(stat, '잘못된 이메일 형식입니다. 이메일에 맞춰 작성해주세요.', 'id');
                this.getInputFocus('id');
            }

            return stat;
        },
        getPwCheck() {
            if(this.login['pw']['value'] !== this.login['pwChk']['value']) 
            {
                this.getErrorText(false, '비밀번호가 일치하지 않습니다.', 'pwChk');
                this.getInputFocus('pwChk');
                return false;
            } 
            else 
            {
                this.getErrorText(true, '', 'pwChk');
                return true;
            }
        },
        getPwRecomm() {
            if(this.login['pw']['value'].length < 6) 
            {
                this.getErrorText(false, '비밀번호는 최소 6자리 이상입니다.', 'pw');
                this.getInputFocus('pw');
                return false;
            } 
            else 
            {
                this.getErrorText(true, '', 'pw');
                return true;
            }
        },
        getErrorText(gb, text, id){
            if(!gb)
            {
                this.login[id]['text']  = text;
                this.login[id]['class'] = 'error';
            }
            else
            {
                this.login[id]['text']  = this.login[id]['class'] === 'error' ?  '' : this.login[id]['text'];
                this.login[id]['class'] = '';
            }
        },
        getInputFocus(id){
            const inputElement = document.getElementById(id);
            if (inputElement) 
            {
                inputElement.focus();
            }
        },
        async getJoinNext() {
            const inputCheck = this.getInputCheck();

            if(!inputCheck)
            {
                return false;
            }

            if(await this.getDuplicateCheck())
            {
                this.getConfirmSet('confirm', '회원가입', '회원가입을 완료하시겠습니까?');
            }
        },
        async getDuplicateCheck() {
            const params  = {
                type    : this.$route.query.type, 
                email   : this.login['id']['value']
            };
            
            try
            {
                const res = await getAxiosData('https://data.wecls.com/join/duplicateCheck', params);

                if(res['status'] !== 200)
                {
                    this.getConfirmSet('alert', '중복', '이미 사용 중인 이메일입니다. 이메일을 변경해주세요.');
                    return false;
                }
            }
            catch(error)
            {
                console.log(error);
                if(error.response.data['status'] === 409)
                {
                    this.getConfirmSet('alert', '중복', '이미 사용 중인 이메일입니다. 이메일을 변경해주세요.');
                }
                else if(error.data['status'] === 400)
                {
                    this.getConfirmSet('alert', '에러', '잘못된 요청입니다. 값을 확인해주세요.');
                }
                else
                {
                    this.getConfirmSet('alert', '에러', '통신 중 에러가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }

                return false;
            }

            return true;
        },
        async getJoinComplete()
        {
            const params = {
                type        : this.$route.query.type,
                email       : this.login['id']['value'],
                pw          : this.login['pw']['value'],
                tel         : this.instrInfo['tel'],
                name        : this.instrInfo['name'],
                birth       : this.instrInfo['birthday'],
                channel     : 'D',
                appAgree    : this.agree['choice'],
                emailAgree  : this.agree['choice'],
                smsAgree    : this.agree['choice'],
                kakaoAgree  : this.agree['choice']
            };

            console.log(params);

            try
            {
                const res = await getAxiosData('https://data.wecls.com/join/joinCheck', params);

                if(res['status'] === 200)
                {
                    this.joinComplete = 'Y';
                    this.getConfirmSet('alert', '완료', '회원가입이 완료되었습니다.');
                }
                else
                {
                    this.joinComplete = 'N';
                    this.getConfirmSet('alert', '실패', '회원가입 도중 문제가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
            catch(error)
            {
                console.log(error);
                this.joinComplete = 'N';
                this.getConfirmSet('alert', '실패', '회원가입 도중 문제가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.join{
    .mobile-content{
        div + div, div + button {
            margin-top: 24px;
        }
    }
}
</style>