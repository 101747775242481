<template>
    <header class="header">
        <div class="inner">
            <h1 class="logo">
                <router-link to="/user/main">
                    <img src="@/assets/images/logo.png" alt="위클즈" title="위클즈" />
                </router-link>
            </h1>
            <input class="search" type="text" id="" name="" />
            <ul class="navi">
                <li>
                    <router-link to="">커뮤니티</router-link>
                </li>
                <li>
                    <router-link to="/user/main">공고보기</router-link>
                </li>
                <li>
                    <router-link to="">즐겨찾기</router-link>
                </li>
                <li>
                    <router-link to="">진행중 서비스</router-link>
                </li>
                <li>
                    <router-link to="">알림</router-link>
                </li>
                <li>
                    <router-link to="/user/mypage/profileMng">마이페이지</router-link>
                </li>
            </ul>
        </div>
    </header>
    <slot />
</template>

<script>
export default {
    name: "UserHeader"
}
</script>

<style lang="scss" scoped>
.header{
    padding: 15px 0;
    background-color: $bk-color-lv-3;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9;

    .logo{
        display: inline-block;
        font-size: $font24;
        font-weight: 700;
        vertical-align: middle;

        a{
            display: flex;
            align-items: center;

            img{
                width: 70px;
            }
        }
    }

    .search{
        display: inline-block;
        width: 613px;
        border-radius: 30px;
        border: 1px solid $line-color-lv-0;
        padding: 12px 24px;
        margin: 0 39px;
        font-size: $font16;
        position: relative;
    }

    .navi{
        display: inline-block;
        align-items: center;

        li{
            color: $text-color-lv-1;
            font-size: $font14;
            font-weight: 700;
            display: inline-block;
            transition: color .1s ease-in;

            &:hover{
                color: $blue-color-lv-4;
            }
        }

        li + li{
            margin-left: 30px;
        }
    }
}
</style>