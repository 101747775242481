<template>
    <div class="section">
        <div class="inner">
            <TableListCard :title="getTitle" :lists="moreList" @get-list="getStatCheck"/>
        </div>
    </div>
</template>

<script>
import TableListCard from "@/components/card/TableListCard.vue";

import { mapState } from "vuex";
import { getMainMoreList } from "@/assets/js/user/main";

export default {
    name: "UserMain",
    components: { TableListCard },
    created() {
        this.getStatCheck();
    },
    computed: {
        ...mapState('main', {
            moreList : state => state.main.moreList
        }),
        getTitle() {
            const today = new Date();
            const type  = this.$route.params.type;
            let title   = '';

            switch(type) 
            {
                case 'BEST':
                    title = `${today.getMonth() + 1}월달 BEST 강사 모집공고`;
                    break;
                case 'MAIN':
                    title = '전체 강사 모집공고';
                    break;
                case 'LIKE':
                    title = '내가 즐겨찾기한 기관 강의 공고';
                    break;
            }

            return title;
        }
    },
    methods: {
        getStatCheck() {
            const type   = this.$route.params.type;
            const params = {
                order : 'ASC',
                start : 0,
                limit : 32
            };

            switch(type)
            {
                case 'BEST':
                    params['bestMore'] = 'Y';
                break;
                case 'MAIN':
                    params['mainMore'] = 'Y';
                break;
                case 'LIKE':
                    params['likeMore'] = 'Y';
                break;
            }

            getMainMoreList(params, type);
        }
    }
}
</script>

<style lang="scss" scoped>
.banner {
    margin: 46px 0;
}

.tag-box {
    border-top: 1px solid $line-color-lv-3;
    padding: 24px 0;
}
</style>