import { getAxiosData, getCommas } from "@/assets/js/function";
import store from '@/store';
import { useRouter } from "vue-router";

/**
 * @description 기관 정산 관리
 */
export async function getPaymentList(params)
{
    console.log(params);

    const router = useRouter();

    try
    {
        let wait = 0;
        let proc = 0;
        let comp = 0;

        const list = [];
        const res  = await getAxiosData("https://data.wecls.com/organ/dashboard/calcList", params);

        console.log(res);

        /** 점수 및 카운트 헤더 */
        const headers = [
            { title : '결제 대기', count : getCommas(Number(res.data['cnt']['waitAmt'])) },
            { title : '결제 확인중', count : getCommas(Number(res.data['cnt']['procAmt'])) },
            { title : '결제 완료', count : getCommas(Number(res.data['cnt']['compAmt'])) },
            { title : '총금액', count : getCommas(Number(res.data['cnt']['total'])) }
        ];

        store.commit("payment/setHeaders", headers);

        res.data['list'].map((item) => {
            switch(item.status)
            {
                case '결제 대기':
                    wait++;
                break;
                case '결제 확인중':
                    proc++;
                break;
                case '결제 완료':
                    comp++;
                break;
            }

            list.push({
                mtCd        : item.mtCd,
                calcCd      : item.calcCd,
                status      : item.status,
                lectPeriod  : item.lectPeriod,
                name        : item.name,
                subject     : item.subject,
                amt         : getCommas(Number(item.amt)),
                receipDt    : item.receipDt ? item.receipDt.slice(0, -9) : '',
                payDay      : item.payDay ? item.payDay.slice(0, -9) : '',
                payGb       : item.payGb,
                payDoc      : item.payDoc,
                calcGb      : item.calcGb !== 'N' ? (item.dayPay === 'E' ? 'Y' : '승인완료') : ''
            })
        });

        const filters = [
            { title : '결제 대기', count : wait },
            { title : '결제 확인중', count : proc },
            { title : '결제 완료', count : comp },
            { title : '전체', count : list.length }
        ];

        store.commit('payment/setFilters', filters);
        store.commit('payment/setAllList', list);
    }
    catch(error)
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'organ');
            router.push('/organLogin');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}

/**
 * @description 기관 정산 정보
 */
export async function getPaymentInfo(params)
{
    console.log(params);

    const router = useRouter();

    try
    {
        const res  = await getAxiosData("https://data.wecls.com/organ/dashboard/calcInfo", params);

        console.log(res);

        const lcInfo  = res.data['lcInfo'];
        const payInfo = res.data['payInfo'];

        const info = {
            lcInfo      : lcInfo,
            totalAmt    : Number(payInfo['totalAmt']),
            couponAmt   : '',
            coupon      : '',
            point       : {
                own : '',
                use : ''
            },
            calcAmt     : Number(payInfo['calcAmt']),
            payGb       : payInfo['payGb'] ? payInfo['payGb'] : 'A', /** 계좌이체 : 'A' / 신용/체크카드 : 'C' */
            bankNm      : payInfo['bankNm'] ? payInfo['bankNm'] : '',
            bankAcc     : payInfo['bankAcc'] ? payInfo['bankAcc'] : '',
            bankNum     : payInfo['bankNum'] ? payInfo['bankNum'] : '',
            payRec      : true,
            payDoc      : payInfo['payDoc'] ? payInfo['payDoc'] : 'T', /** 세금계산서 : 'T' / 현금영수증 : 'C' */
            einNm       : payInfo['contents'] ? payInfo['contents']['einNm'] : '',
            einNum      : payInfo['contents'] ? payInfo['contents']['einNm'] : '',
            einType     : payInfo['contents'] ? payInfo['contents']['einType'] : '',
            einItem     : payInfo['contents'] ? payInfo['contents']['einItem'] : '',
            ceoNm       : payInfo['contents'] ? payInfo['contents']['ceoNm'] : '',
            addr        : payInfo['contents'] ? payInfo['contents']['addr'] : '',
            email       : payInfo['contents'] ? payInfo['contents']['email'] : '',
            cashNum     : payInfo['contents'] ? payInfo['contents']['cashNum'] : '',
            stat        : payInfo['payYn'] ? payInfo['payYn'] : 'N'
        }

        store.commit('payment/setInfo', info);
    }
    catch(error)
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout', 'organ');
            router.push('/organLogin');
        }
        else
        {
            alert(error.response.data.data+'입니다. 이전 페이지로 이동합니다.');
            history.back();
        }
    }
}