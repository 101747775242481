<template>
    <div class="round">
        <div class="round-box">
            <div class="round-header">
                <BtnClose fill="#727981" @click="closeModal" />
                <h2>사용약관</h2>
            </div>

            <div class="round-body">
                <h2>위클즈 서비스 이용 약관</h2>
                <div class="round-content" v-html="contents"></div>
            </div>
        </div>
    </div>
</template>

<script>
import BtnClose from "@/assets/images/BtnClose.vue";

export default {
    name: "RoundPopup",
    props: {
        contents   : String
    },
    components: {BtnClose},
    methods: {
        closeModal(){
            this.$emit('get-close');
        }
    }
}
</script>

<style lang="scss" scoped>
.round{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.6);
    z-index: 2;
    align-items: center;

    &-box{
        margin: 2rem auto 0 auto;
        border-radius: 0.875rem;
        background-color: $bk-color-lv-3;
        overflow: hidden;
        width: 720px;
        height: calc(736px - 2rem);
        padding: 1.25rem;
    }

    &-header{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0.75rem;

        svg{
            cursor: pointer;
        }

        h2{
            width: 100%;
            text-align: center;
            font-size: 0.875rem;
        }
    }

    &-body{
        padding: 2rem 0;
        @include hiddenScroll;
        height: calc(100% - 30px);

        h1{
            font-weight: 700;
            font-size: 2.125rem;
            color: $text-color-lv-0;
        }
    }
}
</style>