<template>
    <div class="side" :class="{ 'active': menuOpen }">
        <div class="side-header">
            <h3>{{ ogNm !== '' ? ogNm+' 기관' : '' }} </h3>
            <img src="@/assets/images/icon-menu.svg" alt="toggle" title="toggle" @click="toggleMenu" />
        </div>
        <ul>
            <li v-for="(navi, index) in navis" :key="index" :class="{ 'active' : isLinkActive(navi.to) }">
                <router-link :to="navi.to">
                    <span>
                        {{ navi.name }}
                    </span>
                </router-link>
            </li>
        </ul>
        <router-link to="/admin/logout" class="logout">로그아웃</router-link>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: "MainSideHeader",
    computed: {
        ...mapState('login', {
            ogNm    : state => state.login.ogNm
        })
    },
    data() {
        return {
            navis : [
                { to: "/admin/main", name: "대시보드" },
                { to: "/admin/schedule", name: "일정" },
                { to: "/admin/collectMng", name: "강사 모집 등록" },
                { to: "/admin/userMng", name: "공고 지원자 관리" },
                { to: "/admin/lectMng", name: "강의 관리" },
                { to: "/admin/userFind", name: "강사 검색" },
                { to: "/admin/gradeMng", name: "리뷰 / 평점 관리" },
                { to: "/admin/message", name: "메세지" },
                { to: "/admin/profileMng", name: "기관 정보 관리" },
                { to: "/admin/paymentMng", name: "결제관리" },
            ],
            menuOpen : true
        }
    },
    methods: {
        isLinkActive(to) {
            return this.$route.path === to;
        },
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        }
    }
}
</script>

<style lang="scss" scoped>
.side{
    position: fixed;
    top: 0;
    left: 0;
    background-color: $bk-color-lv-2;
    padding: 19px 22px;
    height: 100vh;
    z-index: 10;
    transition: width .3s ease;
    width: 240px;

    &.active{
        width: 240px;
    }

    &-header{
        font-size: $font18;
        font-weight: 700;
        position: relative;
        margin-bottom: 25px;

        img{
            @include my-auto;
            right: 0;
            cursor: pointer;
        }
    }

    ul{
        li{
            font-size: $font14;
            font-weight: 500;

            &:hover, &.active{
                a{
                    span{
                        box-shadow: 0 -3px 0 0 $blue-color-lv-4 inset;
                    }
                }
            }

            a{
                display: block;

                span{
                    display: inline-block;
                    padding: 12px 0;
                    transition: all .2s ease;
                }
            }
        }
    }

    .logout{
        color: $text-color-lv-4;
        font-size: $font12;
        position: absolute;
        bottom: 22px;
        left: 19px;
    }
}
</style>