<template>
    <div class="grey">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "GreySelectBox"
}
</script>

<style lang="scss" scoped>
.grey{
    padding: 20px 10px;
    background-color: $bk-color-lv-1;
    display: flex;

    &::after{
        content: '';
        display: block;
        clear: both;
    }
}
</style>