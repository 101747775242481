<template>
    <div class="calender admin-div">
        <div class="inner section">
            <div class="calender-wrapper margin">
                <FullCalendar :options="calendarOptions" />
                <!-- https://fullcalendar.io/docs/vue -->
            </div>
        </div>
    </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import { mapState } from "vuex"
import { getDateAdmInfo } from '@/assets/js/admin/dateAdm'

export default {
    name: "AdmSchedule",
    components: { FullCalendar },
    created() {
        this.getStatCheck()
    },
    computed: {
        ...mapState('login', {
            ogNm    : state => state.login.ogNm
        }),
        ...mapState('dateAdm', {
            info : state => state.dateAdmInfo.info,
        }),
        calendarOptions() {
            return {
                plugins         : [dayGridPlugin],
                initialView     : 'dayGridMonth',
                events          : this.info,
                headerToolbar   : {
                    start   : 'prev',
                    center  : 'title',
                    end     : 'next'
                },
                titleFormat     : { year: 'numeric', month: 'long' },
                locale: 'kr',
            };
        },
    },
    methods: {
        getStatCheck() {
            getDateAdmInfo();
        }
    }
}
</script>

<style lang="scss" scoped>
.calender {
    padding: 90px 0;

    .margin {
        margin-top: 46px;
    }

    .fc{
        .fc-header-toolbar{
            position: relative;
            display: block;

            .fc-toolbar-chunk {
                text-align: center;

                .fc-toolbar-title{
                    width: 100%;
                    text-align: center;
                }

                .fc-today-button{
                    display: none;
                }

                .fc-button{
                    border: none;
                    border-radius: 0;
                }

                .fc-button-primary{
                    background-color: inherit;
                    border: none;

                    &:focus{
                        box-shadow: none;
                    }

                    &.fc-prev-button{
                        position: absolute;
                        top: 0;
                        left: 35%;

                        .fc-icon-chevron-left::before{
                            content: "◀";
                            color: #FF5000;
                        }
                    }

                    &.fc-next-button{
                        position: absolute;
                        top: 0;
                        right: 35%;

                        .fc-icon-chevron-right::before{
                            content: "▶";
                            color: #FF5000;
                        }
                    }
                }
            }
        }
    }

    .notice-title {
        font-size: $font28;
        font-weight: 700;
        margin-bottom: 46px;

        p {
            font-weight: 500;

            span {
                color: $blue-color-lv-4;
            }
        }
    }
}
</style>