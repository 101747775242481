import { getDate, getConvertDate } from "@/assets/js/function";

const date = getDate('mon');

/**
 * @description 정산내역 vuex 모듈 관련
 * @author 정해원, @date 2023-11-22 / (수정) @author 김원명, @date 2024-01-02
 */
const paymentModule = {
    namespaced  : true,
    state       : {
        payment : {
            search      : {
                type    : { name : '이달', value : 'mon' },
                startDt : getConvertDate(date['st'], 'yyyy-mm-dd'),
                endDt   : getConvertDate(date['et'], 'yyyy-mm-dd')
            },
            headers     : [
                { title : '결제 대기', count : 0 },
                { title : '결제 확인중', count : 0 },
                { title : '결제 완료', count : 0 },
                { title : '총금액', count : 0 }
            ],
            filters  : [
                { title : '결제 대기', count : 0 },
                { title : '결제 확인중', count : 0 },
                { title : '결제 완료', count : 0 },
                { title : '전체', count : 0 }
            ],
            allList     : [],
            list        : [],
            info        : getPaymentInfo()
        }
    },

    mutations : {
        setHeaders(state, newHeaders)
        {
            state.payment.headers = newHeaders
        },
        setFilters(state, newFilters)
        {
            state.payment.filters = newFilters
        },
        setAllList(state, newAllList)
        {
            state.payment.allList = newAllList;
            state.payment.list    = newAllList;
        },
        setList(state, newList)
        {
            state.payment.list = newList;
        },
        setInfo(state, newInfo)
        {
            state.payment.info = newInfo;
        }
    }
}

function getPaymentInfo()
{
    return {
        lcInfo      : [],
        totalAmt    : '',
        couponAmt   : '',
        coupon      : '',
        point       : {
            own : '',
            use : ''
        },
        calcAmt     : '',
        payGb       : 'A', /** 계좌이체 : 'A' / 신용/체크카드 : 'C' */
        bankNm      : '',
        bankAcc     : '',
        bankNum     : '',
        payRec      : true,
        payDoc      : 'T', /** 세금계산서 : 'T' / 현금영수증 : 'C' */
        einNm       : '',
        ceoNm       : '',
        einNum      : '',
        einItem     : '',
        einType     : '',
        addr        : '',
        email       : '',
        cashNum     : '',
        stat        : 'N'
    }
}
  
export default paymentModule;