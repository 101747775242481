<template>
    <div class="lect">
        <MypageHeader />
        <div class="inner section">
            <NoticeBox :lists="noticeLists" showTitle title="강의 관리" />
            <GreySelectBox class="margin">
                <Select :selectId="'type'" :selectOptions="dataType" :selectVal="search['type']" :selectChange="getSelectSet" class="small" :labelText="'기간 구분'" />
                &nbsp;
                <DateBox class="regular" :inputId="'startDt'" :inputValue="search['startDt']" :selectFunction="getSelectDate" :timePicker="false" />
                &nbsp;&nbsp;
                <DateBox class="regular" :inputId="'endDt'" :inputValue="search['endDt']" :selectFunction="getSelectDate" :timePicker="false" />
            </GreySelectBox>
            <FilterBox :filters="filters" class="margin" @filter-changed="getListChange" />
            <BasicTable :columns="columns" :rows="list" class="margin" @row-click="getListClick"/>
        </div>
    </div>
</template>

<script>
import Select from "@/components/input/Select.vue";
import GreySelectBox from "@/components/common/GreySelectBox.vue";
import FilterBox from "@/components/common/FilterBox.vue";
import BasicTable from "@/components/table/BasicTable.vue";
import MypageHeader from "@/views/user/mypage/MypageHeader.vue";
import NoticeBox from "@/components/common/NoticeBox.vue";
import DateBox from "@/components/input/DateBox.vue";

import { mapState } from "vuex";
import { getLectList } from "@/assets/js/user/lect";
import { getDate, getConvertDate } from "@/assets/js/function";

export default {
    name: "GrageMng",
    components: { Select, DateBox, FilterBox, NoticeBox, MypageHeader, BasicTable, GreySelectBox },
    data() {
        return {
            noticeLists : [
                {content: "강사를 최종 선발 완료한 이후 사용가능하며, 강의가 진행된 이후 강의완료 처리를 해주셔야 합니다."},
                {content: "강사 또는 기관의 사정으로 강의가 취소된 경우 취소처리로 해주세요."},
                {content: "최종 선발된 강사가 모든 강의를 진행한 경우 진행완료로 처리 해주세요."}
            ],
            columns     : [
                { key: "regDt", header: "매칭 일시", class: "w12" },
                { key: "lectStart", header: "강의 시작일", class: "w7" },
                { key: "lectEnd", header: "강의 마감일", class: "w6" },
                { key: "name", header: "기관명", class: "w6" },
                { key: "subject", header: "강의명", class: "w28 blue" },
                { key: "amt", header: "강의금액", class: "w7" },
                { key: "compYn", header: "완료 여부", class: "w6" }
            ]
        }
    },
    created() {
        this.getCheck()
    },
    computed: {
        ...mapState('data', {
            dataType : state => state.data.dateType
        }),
        ...mapState('lect', {
            search  : state => state.lect.search,
            filters : state => state.lect.filters,
            allList : state => state.lect.allList,
            list    : state => state.lect.list
        })
    },
    methods: {
        getCheck() {
            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                start   : 0,
                limit   : 50
            };

            getLectList(params);
        },
        getSelectSet(id, event) {
            this.search[id] = event;

            const value = event['value'];
            const date  = getDate(value);

            this.search['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
            this.search['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');

            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                start   : 0,
                limit   : 50
            };

            getLectList(params);
        },
        getSelectDate(date, id) {
            this.search[id] = date;

            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                start   : 0,
                limit   : 50
            };

            getLectList(params);
        },
        getListClick(data) {
            console.log(data);
            // this.$router.push({ path: '/user/main/detail', query: { lpCd: data['lpCd'] } });
        },
        getListChange(params) {
            if(params['title'] === '전체')
            {
                this.$store.commit('lect/setList', this.allList);
            }
            else
            {
                const list = this.allList.filter((item) => {
                    let compYn = '강의완료';

                    if(params['title'] === '진행 강의')
                    {
                        compYn = '진행중';
                    }

                    return item.compYn === compYn;
                });

                this.$store.commit('lect/setList', list);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.lect {
    padding: 90px 0;

    .margin {
        margin-top: 46px;
    }
}

.alert {
    .alert-content {
        text-align: center;
        font-size: $font12;
        font-weight: 400;
        color: $text-color-lv-1;
    }

    textarea {
        padding: 14px;
        border-radius: 4px;
        border: 1px solid $line-color-lv-2;
        background-color: $bk-color-lv-3;
        width: 300px;
        height: 120px;
        resize: none;
        margin-bottom: 25px;
        font-size: $font14;
        font-weight: 400;
        color: $text-color-lv-1;
    }
}
</style>