<template>
    <div class="contact-box">
        <div class="contact-input">
            <label for="email">이메일 주소</label>
            <input type="email" id="email" name="email" placeholder="예. admin@gmail.com"/>
        </div>
        <div class="contact-input">
            <label for="contact">문의하실 유형</label>
            <input type="text" id="contact" name="contact" placeholder="예. 이용문의, 강의문의, 사업제안 등"/>
        </div>
        <div class="contact-input">
            <label for="detail">문의 세부내용</label>
            <input type="text" id="detail" name="detail" placeholder="예. 이력서 등록에 대해 궁금해요!"/>
        </div>
        <div class="contact-input">
            <label for="tel">담당자 이메일 및 전화번호</label>
            <input type="text" id="tel" name="tel" placeholder="담당자 분의 연락처를 입력해주세요."/>
        </div>

        <div class="contact-input">
            <div class="flex">
                <input type="checkbox" name="privacy" id="privacy" checked class="mr-8">
                <label for="privacy" class="mb-0">개인정보 처리방침에 동의합니다
                </label>
            </div>
            <p>
                (목적) 상담 | (항목) 이메일, 전화번호 | (보유 및 이용기간) 최초 동의 시로부터 6개월
            </p>
        </div>

        <button>문의 보내기</button>
    </div>
</template>

<script>
export default {
    name: "ContactBox"
}
</script>

<style lang="scss" scoped>
.contact-box {
    width: 640px;
    padding: 60px 40px;
    background-color: $bk-color-lv-3;
    border-radius: 6px;
    @include shadow8;
    margin: 0 auto;

    .contact-input {
        text-align: left;

        .flex{
            align-items: center;
        }

        &:not(:last-child) {
            margin-bottom: 39px;
        }

        label {
            font-size: $font20;
            font-weight: 700;
            display: block;
            margin-bottom: 23px;
        }

        p{
            display: block;
            color: $text-color-lv-3;
            font-size: $font14;
            font-weight: 400;
            margin-left: 25px;
            margin-top: 5px;
        }

        input {
            display: block;
            width: 100%;
            background-color: $bk-color-lv-3;
            border: 1px solid $text-color-lv-3;
            border-radius: 6px;
            padding: 16px 18px;

            &[type="checkbox"]{
                max-width: 16px;
                max-height: 16px;
                width: 100%;
                height: 100%;
                color: white;
                accent-color: black;
            }
        }
    }

    button{
        background-color: $text-color-lv-2;
        color: $bk-color-lv-3;
        border-radius: 8px;
        text-align: center;
        font-size: $font20;
        font-weight: 700;
        width: 100%;
        padding: 17px;
    }
}
</style>